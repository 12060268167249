import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ZCreateK from './ZCreateK';

export default function CreateK() {
  const { jID } = useParams()
  const [showCreateXa, setShowCreateXa] = useState(false)
  const [showCreateZa, setShowCreateZa] = useState(false)
  const [showCreateGb, setShowCreateGb] = useState(false)
  const [showCreateGe, setShowCreateGe] = useState(false)

  return (
    <>

    {/** Navbar */}
    <div className="flex font-mono gap-1 text-xs ml-4 py-1">
    <>
      Add a block of type: 
      {!showCreateXa && 
        <div onClick={()=>{
          setShowCreateXa(true); 
          setShowCreateGb(false)
          setShowCreateZa(false)
          setShowCreateGe(false)
        }} 
          className='flex font-bold text-slate-700 hover:cursor-pointer hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
        >
          Xa
        </div>
      }
      {showCreateXa && 
        <div onClick={()=>{setShowCreateXa(false)}} 
          className='flex font-bold text-abhi-link hover:cursor-pointer hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
        >
          Xa
        </div>
      }
      |
      {!showCreateZa && 
        <div onClick={()=>{
          setShowCreateZa(true); 
          setShowCreateXa(false)
          setShowCreateGb(false)
          setShowCreateGe(false)
        }} 
          className='flex font-bold text-slate-700 hover:cursor-pointer hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
        >
          Za
        </div>
      }
      {showCreateZa && 
        <div onClick={()=>{setShowCreateZa(false)}} 
          className='flex font-bold text-abhi-link hover:cursor-pointer hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
        >
          Za
        </div>
      }
      |
      {!showCreateGb && 
        <div onClick={()=>{
          setShowCreateGb(true);
          setShowCreateGe(false)
          setShowCreateXa(false)
          setShowCreateZa(false)
        }} 
          className='flex font-bold text-slate-700 hover:cursor-pointer hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
        >
          Gb
        </div>
      }
      {showCreateGb && 
        <div onClick={()=>{setShowCreateGb(false)}} 
          className='flex font-bold text-abhi-link hover:cursor-pointer hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
        >
          Gb
        </div>
      }
      |
      {!showCreateGe && 
        <div onClick={()=>{
          setShowCreateGe(true);
          setShowCreateGb(false);
          setShowCreateXa(false)
          setShowCreateZa(false)
        }} 
          className='flex font-bold text-slate-700 hover:cursor-pointer hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
        >
          Ge
        </div>
      }
      {showCreateGe && 
        <div onClick={()=>{setShowCreateGe(false)}} 
          className='flex font-bold text-abhi-link hover:cursor-pointer hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
        >
          Ge
        </div>
      }
    </>
      
    </div>
    {/** Navbar ends here */}

    <div className='grid  py-3'>
      <div className="px-4 sm:px-12 pb-5">

        {showCreateXa &&
          <ZCreateK jID={jID} kType={"Xa"} />
        }
        {showCreateZa &&
          <ZCreateK jID={jID} kType={"Za"} />
        }
        {showCreateGb && 
          <ZCreateK jID={jID} kType={"Gb"} />
        }
        {showCreateGe && 
          <ZCreateK jID={jID} kType={"Ge"} />
        }
        
      </div>
    </div>
    </>
  )
}
