import AbhiNameWithLogo from "../../components/AbhiNameWithLogo";

export default function Home() {

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-2 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          {/*<AbhiNameWithLogo />*/}
          <div className="mt-10 text-center text-l font-mono leading-9 tracking-tight text-gray-900">
            Homepage
          </div>

          <p className="text-center font-mono text-xs text-gray-500">
            Coming soon!
          </p>
        </div>
      </div>
    </>
  )
}
