import { useDocument } from '../../hooks/useDocument'
import { useParams } from 'react-router-dom';
import ZCreateKNGb from './ZCreateKNGb';
import ZCreateKNXa from './ZCreateKNXa';
import ZCreateKNGe from './ZCreateKNGe';
import ZCreateKNZa from './ZCreateKNZa';

// create a KNM N node for block type E01
// within the block kID (in project jID) for text edition
// a node is created ONLY within a project/block by those who have r5/admin role
// the node is visible always to all members of WG in their respective roles
// a node is visible to any within preview to even any registered user temporarily

// when a node is created, it is
// 1. created and placed at N:[] array of block kID of KNM Collection

// we do not need node type as the type is set by block
// we have block types and not node types

// 1. N:[] array of the block kID of KNM Collection
// a z g q t nID 
// nID unique node ID also timestamp Date.now()
// x : display text the MAIN text
// z : raw entry text
// q : for sequencing
// other fields later 
// these fields will represent the flexibility of the system
// for sandhi anvaya anuvada translation explanatory notes critical comments

export default function CreateKN() {
  const { jID, kID } = useParams()
  const { error, document } = useDocument('KNM', kID)

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading ...</div>
  }

  const block = document

  return (
    <>
      <div className="px-4 sm:px-12 pb-5">
        {block.g === "Xa" && 
          <div>
            <ZCreateKNXa jID={jID} kID={kID} />
          </div>
        }
        {block.g === "Za" && 
          <div>
            <ZCreateKNZa jID={jID} kID={kID} />
          </div>
        }
        {block.g === "Gb" && 
          <div>
            <ZCreateKNGb jID={jID} kID={kID} />
          </div>
        }
        {block.g === "Ge" && 
          <div>
            <ZCreateKNGe jID={jID} kID={kID} />
          </div>
        }
      </div>
      
    </>
  )
}
