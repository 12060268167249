import { useState } from 'react'
import { useAuthContext } from './useAuthContext'

// firebase imports
import { auth, db } from '../firebase/config'
import { doc, updateDoc } from "firebase/firestore"
import { signInWithEmailAndPassword } from 'firebase/auth'

export const useLogin = () => {
  const [error, setError] = useState(null)
  const { dispatch } = useAuthContext()

  const login = (email, password) => {
    setError(null)
    signInWithEmailAndPassword(auth, email, password)
      .then((res) => {
        dispatch({ type: 'LOGIN', payload: res.user })
          updateDoc(doc(db, 'USERS', res.user.uid),{ o: true }) // o online status
      })
      .catch((err) => {
        setError(err.message)
      })
  }

  return { error, login }
}