import { useDocument } from "../../hooks/useDocument"
import { useFirestore } from "../../hooks/useFirestore"
import { useEffect, useState } from "react"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useNavigate, useParams } from "react-router-dom"

export default function KNMEditq() {
  const { user } = useAuthContext()
  const { jID, kID } = useParams()
  const { error, document } = useDocument('Projects', jID)
  const { updateDocument, response } = useFirestore('Projects') 
  const navigate = useNavigate()
  const [q, setQ] = useState('')

  // KNM: List of blocks
  useEffect(() => {
    if (document && document.KNM){ 
      let result = document.KNM.filter(obj => {
        return obj.kID === kID
      })
      if (result.length > 0) {
        setQ(result[0].q)
      }
    }
  }, [document])

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading ...</div>
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const newKNMs = document.KNM.map( knm => {
      if (knm.kID === kID) {
        return {...knm, q:q}
      } else {
        return {...knm}
      }
    })

    const updates = { 
      KNM: newKNMs
    }

    await updateDocument(jID, updates)
    if (!response.error) {
      navigate(`/projectoverview/${jID}`)
    }
  }

  return (
    <>

    <div className="flex justify-center font-mono">
      Edit Sequence
    </div>

    <div className="flex justify-center font-mono">
      <form onSubmit={handleSubmit}>
          <input 
              id="Q"
              type="text" 
              onChange={(e) => setQ(e.target.value)}
              value={q}
          />
        <div>
          <button 
            type='submit'
            className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
            >
              Update
          </button>
        </div>
      </form>
    </div>
    </>
  )
}
