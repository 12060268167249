import { t } from '@sanskrit-coders/sanscript'

const xForm = () => {

  const xFormHKToDev = (s) => {
    return t(s, 'hk', 'devanagari')
      .replace(/\./gm,'।')
      .replace(/\;/gm,'॥')
      .replace(/\,/gm,'&#x2240;')
      .replace(/\//gm,'<br/>')
      .replace(/\\/gm,'')
  }

  const xFormXa = (s) => {
    return t(s, 'hk', 'devanagari')
      .replace(/\./gm,'।')
      .replace(/\;/gm,'॥')
      .replace(/\,/gm,'&#x2240;')
      .replace(/\//gm,'<br/>')
      .replace(/\\/gm,'')
      .replace(/\[\-/gm,'<span class="text-pink-600 font-bold" >')
      .replace(/\-\]/gm,'</span>')
      .replace(/\{\-/gm,'<span class="text-orange-900 font-semibold" >')
      .replace(/\-\}/gm,'</span>')
      .replace(/\[\+/gm,'<span class="text-slate-500 font-semibold" >')
      .replace(/\+\]/gm,'</span>')
      .replace(/\{\+/gm,'<span class="text-slate-500 font-medium" >')
      .replace(/\+\}/gm,'</span>')
      .replace(/\[\_/gm,'<span class="text-orange-900 font-medium" >')
      .replace(/\_\]/gm,'</span>')
      .replace(/\{\_/gm,'<span class="text-orange-700 font-medium" >')
      .replace(/\_\}/gm,'</span>')
      .replace(/\[\!/gm,'<span class="text-slate-800 font-semibold" >')
      .replace(/\!\]/gm,'</span>')
      .replace(/\(\?/gm,'<span class="text-sky-700 font-thin">')
      .replace(/\?\)/gm,'</span>')
      .replace(/\(\!/gm,'<span class="text-sky-900 font-semibold">')
      .replace(/\!\)/gm,'</span>')
        .replace(/\(\%/gm,'<span style="color:Gray;"><small>')
        .replace(/\%\)/gm,'</small></span>')
        .replace(/\(\+/gm,'<span style="text-decoration: underline SteelBlue; text-decoration-style: dashed; text-underline-offset: 8px;">')
        .replace(/\+\)/gm,'</span>')
        .replace(/\(\*/gm,'<span style="color:SteelBlue;">')
        .replace(/\*\)/gm,'</span>')
        .replace(/\(\=/gm,'<span style="color:IndianRed;">')
        .replace(/\=\)/gm,'</span>')
       
}

const xFormXaZ = (s) => {
  return s
    .replaceAll(/(\#)([A-z0-9-',.;~]+)/gm,
      (...match) => t(`${match[2]}`,'hk','iast'))
    .replaceAll(/(\$)([A-z0-9-',.;~]+)/gm,
      (...match) => t(`${match[2]}`,'hk','devanagari'))
    .replace(/\//gm,'<br/>')
    .replace(/\\/gm,'')
    .replace(/\[\-/gm,'<span class="text-pink-600 font-bold" >')
    .replace(/\-\]/gm,'</span>')
    .replace(/\{\-/gm,'<span class="text-orange-900 font-semibold" >')
    .replace(/\-\}/gm,'</span>')
    .replace(/\[\+/gm,'<span class="text-slate-500 font-semibold" >')
    .replace(/\+\]/gm,'</span>')
    .replace(/\{\+/gm,'<span class="text-slate-500 font-medium" >')
    .replace(/\+\}/gm,'</span>')
    .replace(/\[\_/gm,'<span class="text-orange-900 font-medium" >')
    .replace(/\_\]/gm,'</span>')
    .replace(/\{\_/gm,'<span class="text-orange-700 font-medium" >')
    .replace(/\_\}/gm,'</span>')
    .replace(/\[\!/gm,'<span class="text-slate-800 font-semibold" >')
    .replace(/\!\]/gm,'</span>')
      .replace(/\(\%/gm,'<span style="color:Gray;"><small>')
      .replace(/\%\)/gm,'</small></span>')
      .replace(/\(\+/gm,'<span style="text-decoration: underline SteelBlue; text-decoration-style: dashed; text-underline-offset: 8px;">')
      .replace(/\!\)/gm,'</span>')
      .replace(/\(\!/gm,'<span style="text-decoration: underline IndianRed; text-decoration-style: double; text-underline-offset: 8px;">')
      .replace(/\+\)/gm,'</span>')
      .replace(/\(\*/gm,'<span style="color:SteelBlue;">')
      .replace(/\*\)/gm,'</span>')
      .replace(/\(\=/gm,'<span style="color:IndianRed;">')
      .replace(/\=\)/gm,'</span>')
      .replace(/\(\?/gm,'<span style="color:DarkMagenta;">')
      .replace(/\?\)/gm,'</span>')
    .replaceAll(/(\+)([A-z0-9-',.;~]+)/gm,
      (...match) => ( 
        '<span class="text-pink-600 font-bold" >'+  
        t(`${match[2]}`,'hk','devanagari')+
        '</span>'
      ))
    .replaceAll(/(\*)([A-z0-9-',.;~]+)/gm,
      (...match) => ( 
        '<span class="text-orange-700 font-medium" >'+  
        t(`${match[2]}`,'hk','devanagari')+
        '</span>'
      ))
}

    const selectEditStr = (s) => {
        const regexp = /\[\!([\s\S]*?)\!\]/gm
        var array = [...s.matchAll(regexp)]
        return array.map(m => m[1])
    }
    
    //'hk', 'devanagari'
    const xFormWithAreaTags = (s,sourceScript,targetScript) => {
        return t(s, sourceScript, targetScript)
            .replace(/\./gm,'।')
            .replace(/\;/gm,'॥')
            .replace(/\#/gm,'<span style="color:IndianRed;">&#x259D;&nbsp;</span>')
            .replace(/\(\-/gm,'<span style="text-decoration: underline IndianRed; text-decoration-style: dotted; text-underline-offset: 8px;">')
            .replace(/\-\)/gm,'</span>')
            .replace(/\(\%/gm,'<span style="color:Gray;"><small>')
            .replace(/\%\)/gm,'</small></span>')
            .replace(/\(\+/gm,'<span style="text-decoration: underline SteelBlue; text-decoration-style: dashed; text-underline-offset: 8px;">')
            .replace(/\+\)/gm,'</span>')
            .replace(/\!\)/gm,'</span>')
            .replace(/\(\!/gm,'<span style="text-decoration: underline IndianRed; text-decoration-style: double; text-underline-offset: 8px;">')
            .replace(/\(\*/gm,'<span style="color:SteelBlue;">')
            .replace(/\*\)/gm,'</span>')
            .replace(/\(\=/gm,'<span style="color:IndianRed;">')
            .replace(/\=\)/gm,'</span>')
            .replace(/\(\?/gm,'<span style="color:DarkMagenta;">')
            .replace(/\?\)/gm,'</span>')
            .replace(/\$/gm,'<br/>')
    }

    const xFormHKToDevWithAreaTags = (s) => {
        return t(s, 'hk', 'devanagari')
            .replace(/\./gm,'।')
            .replace(/\;/gm,'॥')
            .replace(/\,/gm,'&#x2240;')
            .replace(/\(\-/gm,'<span style="text-decoration: underline IndianRed; text-decoration-style: dotted; text-underline-offset: 8px;">')
            .replace(/\-\)/gm,'</span>')
            .replace(/\(\%/gm,'<span style="color:Gray;"><small>')
            .replace(/\%\)/gm,'</small></span>')
            .replace(/\(\+/gm,'<span style="text-decoration: underline SteelBlue; text-decoration-style: dashed; text-underline-offset: 8px;">')
            .replace(/\!\)/gm,'</span>')
            .replace(/\(\!/gm,'<span style="text-decoration: underline IndianRed; text-decoration-style: double; text-underline-offset: 8px;">')
            .replace(/\+\)/gm,'</span>')
            .replace(/\(\*/gm,'<span style="color:SteelBlue;">')
            .replace(/\*\)/gm,'</span>')
            .replace(/\(\=/gm,'<span style="color:IndianRed;">')
            .replace(/\=\)/gm,'</span>')
            .replace(/\(\?/gm,'<span style="color:DarkMagenta;">')
            .replace(/\?\)/gm,'</span>')
            .replace(/\$/gm,'<br/>')
    }

    const xFormHKToDevWithLatin = (s) => {
        return s.replaceAll(/(^|\s)(_)([A-z0-9-'.;]+)/gm,
            (...match) => `${match[1]}`+'<b>'+ xFormHKToDev(`${match[3]}`)+'</b>')
    }

    const xFormCategoryTags = (s) => {
        return s
            .replaceAll(/(^|\s)(_)([^\s]+)/gm,
                (...match) => `${match[1]}`+'<strong><span style="color:Black;">'+ xFormHKToDev(`${match[3]}`)+'</span></strong>')
            .replaceAll(/(^|\s)(-)([A-z0-9-'.;~]+)/gm,
                (...match) => `${match[1]}`+'<strong><span style="color:SteelBlue;">'+ xFormHKToDev(`${match[3]}`)+'</span></strong>')
            .replaceAll(/(^|\s)(=)([A-z0-9-'.;~]+)/gm,
                (...match) => `${match[1]}`+'<strong><span style="color:IndianRed;">'+ xFormHKToDev(`${match[3]}`)+'</span></strong>')

            .replaceAll(/(^|\s)(!)([^\s]+)/gm,
                (...match) => `${match[1]}`+'<span style="text-decoration: underline IndianRed; text-decoration-style: dotted; text-underline-offset: 8px;">'+ t(`${match[3]}`,'hk','iast')+'</span>')
                .replaceAll(/(^|\s)(\?)([^\s]+)/gm,
                (...match) => `${match[1]}`+'<span style="text-decoration: underline IndianRed; text-decoration-style: dashed; text-underline-offset: 8px;">'+ t(`${match[3]}`,'hk','iast')+'</span>')
                
                .replaceAll(/(^|\s)(\*)([^\s]+)/gm,
                (...match) => `${match[1]}`+'<mark><span style="color:Black;">'+ `${match[3]}`+'</span></mark>')
                .replaceAll(/(^|\s)(\%)([A-z0-9-'.;~]+)/gm,
                (...match) => `${match[1]}`+'<span style="text-decoration: underline IndianRed; text-decoration-style: single; text-underline-offset: 8px;">'+ `${match[3]}`+'</span>')

            
            .replaceAll(/(^|\s)(:)([^\s]+)/gm,
                (...match) => `${match[1]}`+'<strong><span style="color:IndianRed;">'+ t(`${match[3]}`,'hk','iast')+'</span></strong>')
            .replaceAll(/(^|\s)(;)([^\s]+)/gm,
                (...match) => `${match[1]}`+'<strong><span style="color:SteelBlue;">'+ t(`${match[3]}`,'hk','iast')+'</span></strong>')
            .replaceAll(/(^|\s)(#)([A-z0-9-'.;~]+)/gm,
                (...match) => `${match[1]}`+'<mark><span style="color:Black;">'+ t(`${match[3]}`,'hk','iast')+'</span></mark>')
            .replaceAll(/(^|\s)(\+)([A-z0-9-'.;~]+)/gm,
                (...match) => `${match[1]}`+'<i><span style="color:Black;">'+ t(`${match[3]}`,'hk','iast')+'</span></i>')
            

            .replaceAll(/(^|\s)(\,)([^\s]+)/gm,
            (...match) => `${match[1]}`+'<strong><span style="color:SteelBlue;">'+ `${match[3]}`+'</span></strong>')
            .replaceAll(/(^|\s)(\.)([^\s]+)/gm,
            (...match) => `${match[1]}`+'<strong><span style="color:IndianRed;">'+ `${match[3]}`+'</span></strong>')

            .replaceAll(/(^|\s)(\/)([^\s]+)/gm,
            (...match) => `${match[1]}`+'<i><span style="color:Black;">'+ `${match[3]}`+'</span></i>')

            .replace(/\$/gm,'<br/>')
    }

    return { xFormXa, xFormXaZ,  xFormHKToDev, xFormWithAreaTags, xFormHKToDevWithAreaTags, xFormHKToDevWithLatin, xFormCategoryTags, selectEditStr }
}

export default xForm