import { Link } from 'react-router-dom'

// C I J V
// d e o t uID
// o: T/F online status
export default function ZMyProfile({usersDoc}) {

  return (
    <>
      <div className='font-mono'>

        <div className='flex text-sm '>
          <div className='text-slate-700'>
            &#x2598;
          </div>
          <div className='font-semibold'>
            {usersDoc.d}
          </div>
        </div>

        <div className='text-slate-500 text-xs'>
          {usersDoc.e}
        </div>
      
      </div>
    </>
  )
}
