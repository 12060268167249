import { Link } from "react-router-dom";

export default function ProjectInitiateInfo() {

  return (
    <>
      <div className="px-4 sm:px-8 md:px-12 py-1 ">
        <div className="font-body font-bold italic">
          Project support
        </div>

        <div className='grid md:grid-cols-3 gap-3 py-3'>

          <div className=''>
            <div className="font-mono font-bold text-sm" >
              Editions
            </div>
            <div className='text-xs font-mono text-slate-500'>
              and translations
            </div>

            <div className='flex'>
              <div className='font-mono font-bold text-sm'>
                Initiate 
              </div>
              <div className='pt-1'>
                <Link to="/initiateproject" className="text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4 pt-1">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
                </Link>
              </div>
            </div>

          </div>

          <div className=''>

            <div className="font-mono font-bold text-sm" >
              Readers
            </div>
            <div className='text-xs font-mono text-slate-500'>
              and textbooks
            </div>

          </div>

          <div className=''>

            <div className="font-mono font-bold text-sm" >
              Catalogues
            </div>
            <div className='text-xs font-mono text-slate-500 pb-3'>
              and bibliographies
            </div>

          </div>

        </div>
      </div>
    </>
  )
}
