import { Link } from "react-router-dom";

export default function ZPreviewsList({vList}) {

  // vList = [{ },...]
  // jID id of the project
  // kID of the block
  // aJ: display name title of the project
  // aK: display title of the block
  // iUID ping uID the person sending the invite
  // di: display name of the person
  // ei: email
  // oUID pong uID the person being invited
  // do: name
  // eo: email
  // gJ: project type
  // gK: Block type
  // r9-r1: t/f roles he is supposed to do
  // r9-r3: false r1:true
  // hi: T/F hide says ping
  // ho: T/F hide says pong
  // t: timestamp

  // List of blocks

  return (
    <>
    {
      vList && vList.map(v => (
        <div key={v.kID} className='pb-1'>
          {
            v.aJ && v.aK &&
              <Link 
                to={`/knmoverview/${v.jID}/${v.kID}`} 
                className='font-body text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
              > 
                &#x2598;
                {v.aJ} - {v.aK}
              </Link>
          }
     
        </div>
      ))
    }
    </>
  )
}
