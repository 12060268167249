export default function ZProjectInitiator({project}) {

  return (
    <>
    {/* J: list of my projects q: for sequencing*/}
    {/* project: is one such entry*/}
    {/* jID: unique project ID */}
    {
      project.di && 
        <div className='font-mono text-slate-400 text-xs'>
          {project.di}
        </div>      
    }
    </>
  )
}
