import { useEffect, useState } from "react"
import { db } from "../firebase/config"
import { doc, onSnapshot } from "firebase/firestore";

export const useDocumentA = (c, id) => {
  const [documentA, setDocumentA] = useState(null)
  const [errorA, setErrorA] = useState(null)

  // realtime document data
  useEffect(() => {
    const ref = doc(db, c, id)

    const unsubscribe = onSnapshot(ref, (snapshot) => {
      // need to make sure the doc exists & has data
      if(snapshot.data()) {
        setDocumentA({...snapshot.data(), id: snapshot.id})
        setErrorA(null)
      }
      else {
        setErrorA('No such document exists')
      }
    }, err => {
      console.log(err.message)
      setErrorA('failed to get document')
    })

    // unsubscribe on unmount
    return () => unsubscribe()

  }, [c, id])

  return { documentA, errorA }
}