import { useDocument } from "../../hooks/useDocument"
import { useNavigate, useParams } from "react-router-dom"
import { useDocumentA } from "../../hooks/useDocumentA"
import { toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'

export default function KNMPreviewRelease2() {
  const { jID, kID, email } = useParams()
  const { error, document } = useDocument('EMAILuID', email)
  const { errorA, documentA } = useDocumentA('Projects', jID)
  const navigate = useNavigate()
  const notify = () => toast("Already in Workgroup!")

  if (error) {return (<div className="loading">{email} &nbsp;does not exist!</div>)}
  if (!document) {return <div className="loading">Loading ...</div>}
  if (errorA) {return (<div className="loading">{errorA} </div>)}
  if (!documentA) {return <div className="loading">Loading ...</div>}

  const handleSubmit = async (e) => {
    e.preventDefault()

    // get WG of the project
    let arrWG = documentA.WG 
    // check if oUID is not in WG
    if (arrWG.filter(e => e.oUID === document.uID).length === 0) {
      // navigate to next step
      navigate(`/knmpreviewrelease3/${jID}/${kID}/${document.uID}`)
    } else {
      // navigate to caption already there in WG
      notify()
      navigate(`/knmoverview/${jID}/${kID}`)
    }
  }

  return (
    <>
    <div className="flex justify-center font-body">
      <div>
          {document.d}
      </div>
      &nbsp;
      <div>
        {document.e}
      </div>
    </div>
    <div className="flex justify-center font-body">
      <form onSubmit={handleSubmit}>
        <div>
          <button 
            type='submit'
            className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
            >
              Send request
          </button>
        </div>
      </form>
    </div>
    </>
  )
}
