import { useDocument } from "../../hooks/useDocument"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useNavigate, useParams } from "react-router-dom"
import { useState } from "react"

export default function KNMPreviewRelease1() {
  const { user } = useAuthContext()
  const { jID, kID } = useParams()
  const { error, document } = useDocument('USERS', user.uid)
  const navigate = useNavigate()
  const [email, setEmail] = useState('')

  if (error) {return <div className="error">{error}</div>}
  if (!document) {return <div className="loading">Loading ...</div>}

  const handleSubmit = async (e) => {
    e.preventDefault()
    navigate(`/knmpreviewrelease2/${jID}/${kID}/${email}`)
  }

  return (
    <>
    <div className="flex justify-center bg-white font-body">
      <form onSubmit={handleSubmit}>

        <div>
          <label for='email'>Email</label>
          <div className='mt-2'>
            <input 
              className='' 
              id="email" 
              placeholder="name@example.com"
              type="email" 
              onChange={(e) => setEmail(e.target.value)} 
              value={email}
            />
          </div>
        </div>

        <div>
          <button 
            type='submit'
            className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
            >
              Send request
          </button>
        </div>

      </form>
    </div>
    </>
  )
}
