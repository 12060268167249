import { Link, useNavigate } from 'react-router-dom'

export default function Navbar1() {
  const navigate = useNavigate()

  const handleLogin = () => {
    navigate(`/login`)
  }

  return (
    <>
      <div className="grid grid-cols-2 bg-white font-body sticky top-0 z-50">
        <div className="flex justify-start">
          <Link to="/" className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4 ml-4 mr-2 pt-1'>
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.07926 0.222253C7.31275 -0.007434 7.6873 -0.007434 7.92079 0.222253L14.6708 6.86227C14.907 7.09465 14.9101 7.47453 14.6778 7.71076C14.4454 7.947 14.0655 7.95012 13.8293 7.71773L13 6.90201V12.5C13 12.7761 12.7762 13 12.5 13H2.50002C2.22388 13 2.00002 12.7761 2.00002 12.5V6.90201L1.17079 7.71773C0.934558 7.95012 0.554672 7.947 0.32229 7.71076C0.0899079 7.47453 0.0930283 7.09465 0.32926 6.86227L7.07926 0.222253ZM7.50002 1.49163L12 5.91831V12H10V8.49999C10 8.22385 9.77617 7.99999 9.50002 7.99999H6.50002C6.22388 7.99999 6.00002 8.22385 6.00002 8.49999V12H3.00002V5.91831L7.50002 1.49163ZM7.00002 12H9.00002V8.99999H7.00002V12Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
          </Link>
          <Link to="/readings" className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4 mr-2'>
            readings
          </Link>
          <Link to="/workspace" className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4 mr-2 italic'>
            workspace
          </Link>
        </div>
        
        <div className="flex justify-end">
          <Link to="/support" className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4 mr-2'>
            support
          </Link>
          <button className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4 mr-4' onClick={handleLogin}>
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 1C4.22386 1 4 1.22386 4 1.5C4 1.77614 4.22386 2 4.5 2H12V13H4.5C4.22386 13 4 13.2239 4 13.5C4 13.7761 4.22386 14 4.5 14H12C12.5523 14 13 13.5523 13 13V2C13 1.44772 12.5523 1 12 1H4.5ZM6.60355 4.89645C6.40829 4.70118 6.09171 4.70118 5.89645 4.89645C5.70118 5.09171 5.70118 5.40829 5.89645 5.60355L7.29289 7H0.5C0.223858 7 0 7.22386 0 7.5C0 7.77614 0.223858 8 0.5 8H7.29289L5.89645 9.39645C5.70118 9.59171 5.70118 9.90829 5.89645 10.1036C6.09171 10.2988 6.40829 10.2988 6.60355 10.1036L8.85355 7.85355C9.04882 7.65829 9.04882 7.34171 8.85355 7.14645L6.60355 4.89645Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
          </button>

        </div>
      </div>

    </>
  )
}
