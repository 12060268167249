import { useDocument } from "../../hooks/useDocument"
import { useFirestore } from "../../hooks/useFirestore"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useNavigate, useParams } from "react-router-dom"
import { useDocumentA } from "../../hooks/useDocumentA"
import { useFirestoreA } from "../../hooks/useFirestoreA"
import { useState } from "react"

// within the project jID
// user with user.uid is inviting 
// oUID to join the WG
// when send invitation is pressed

// an invitation packet is stored at
// Projects:jID.I so that we know which invitations are active for this project
// Any WG member of the project can see this invitation

// USERS.oUID.I so that the invited person knows that he is being invited

// The invitation packet consists of:
// jID id of the project
// a: display name title of the project
// iUID ping uID the person sending the invite
// di: display name of the person
// ei: email
// oUID pong uID the person being invited
// do: name
// eo: email
// r9-r1: t/f roles he is supposed to do

// He can either accept this or reject
// for this next step.

// If he accepts then
// Projects jID.WG will include him with this role.
// If he reclines, then this is noted in Projects.jID.I and oUID.I 

export default function WGInviteStep2() {
  const { user } = useAuthContext()
  // oUID uID of the person invited
  const { jID, oUID } = useParams()

  const { error, document } = useDocument('Projects', jID)
  const { errorA, documentA } = useDocumentA('USERS', oUID)

  const { updateDocument, response } = useFirestore('Projects') 
  const { updateDocumentA, responseA } = useFirestoreA('USERS') 

  const [checkedRoles, setCheckedRoles] = useState([])
  const navigate = useNavigate()

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading ...</div>
  }
  if (errorA) {
    return <div className="error">{errorA}</div>
  }
  if (!documentA) {
    return <div className="loading">Loading ...</div>
  }

  const handleCheck = (e) => {
    if (e.target.checked) {
      setCheckedRoles([...checkedRoles, e.target.value]);
    } else {
      setCheckedRoles(checkedRoles.filter((item) => item !== e.target.value));
    }
  }

  // yet to check that I do not send a request to myself!
  const handleSubmit = async (e) => {
    e.preventDefault()

    let r9 = false
    let r7 = false
    let r5 = false
    let r3 = false
    let r1 = false

    if (checkedRoles.includes("r9")) {
      r9 = true
      r7 = true
      r5 = true
      r3 = true
      r1 = true
    } else if (checkedRoles.includes("r7")) {
      r7 = true
      r5 = true
      r3 = true
      r1 = true
    } else if (checkedRoles.includes("r5")) {
      r5 = true
      r3 = true
      r1 = true
    } else if (checkedRoles.includes("r3")) {
      r3 = true
      r1 = true
    } else if (checkedRoles.includes("r1")) {
      r1 = true
    } 

    // t timestamp
    // jID id of the project
    // a: display name title of the project
    // iUID ping uID the person sending the invite
    // di: display name of the person
    // ei: email
    // oUID pong uID the person being invited
    // do: name
    // eo: email
    // g: project.g project type
    // r9-r1: t/f roles he is supposed to do

    let arrInviteProj = document.I // invite in project
    // if the jID is not already there 
    
    if (arrInviteProj.filter(e => e.oUID === oUID).length === 0) {
      arrInviteProj.push({
        t:  Date.now(),
        jID,
        a:document.a, // 
        iUID:user.uid,
        di:user.displayName,
        ei:user.email,
        oUID,
        do:documentA.d,
        eo:documentA.e,
        g:document.g, // project type
        y:false, // yes accepted
        n:false, // no declined
        r9,
        r7,
        r5,
        r3,
        r1
      })
    }

    const updatesInviteProj = { 
      I: arrInviteProj // C Contacts array
    }

    let arrInvitePong = documentA.I // invite 
    // if the jID is not already there 

    if (arrInvitePong.filter(e => e.jID === jID).length === 0) {
      arrInvitePong.push({
        t:  Date.now(),
        jID,
        a:document.a, // 
        iUID:user.uid,
        di:user.displayName,
        ei:user.email,
        oUID,
        do:documentA.d,
        eo:documentA.e,
        g:document.g, // project type
        y:false, // yes accepted
        n:false, // no declined
        r9,
        r7,
        r5,
        r3,
        r1
      })
    }

    const updatesInvitePong = { 
      I: arrInvitePong // I invitation array
    }

    // save updatesInviteProj to Proj
    await updateDocument(jID, updatesInviteProj)
    // save updatesInvitePong to Pong account
    await updateDocumentA(oUID, updatesInvitePong)
    if (!response.error && !responseA.error) {
      navigate(`/projectoverview/${jID}`)
    }
  }

  return (
    <>
    <div>
      {document.a}
    </div>
    <div>
      {documentA.d}
      &nbsp;
      {documentA.e}
    </div>
    <div>
      Other info about this project. and roles!
    </div>
    <div>
      Roles
    </div>
    <div className='col'>
            <input value = "r9" type = "checkbox" onChange = {handleCheck} />
            <span>Owner r9</span>
          </div>
          <div className='col'>
            <input value = "r7" type = "checkbox" onChange = {handleCheck} />
            <span>Manager r7</span>
          </div>
          <div className='col'>
            <input value = "r5" type = "checkbox" onChange = {handleCheck} />
            <span>Admin r5</span>
          </div>
          <div className='col'>
            <input value = "r3" type = "checkbox" onChange = {handleCheck} />
            <span>Editor r3</span>
          </div>
          <div className='col'>
            <input value = "r1" type = "checkbox" onChange = {handleCheck} />
            <span>Reader r1</span>
          </div>

      <form onSubmit={handleSubmit}>

      <div>
          <button 
            type='submit'
            className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
            >
              Send invitation
          </button>
        </div>
      </form>


    </>
  )
}
