import { useDocument } from "../../hooks/useDocument"
import { useFirestore } from "../../hooks/useFirestore"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useNavigate, useParams } from "react-router-dom"
import { useDocumentA } from "../../hooks/useDocumentA"

// now send the request
// check if you do not send a request to yourself!

export default function CollaborationReq3() {
  const { user } = useAuthContext()
  const { error, document } = useDocument('USERS', user.uid)
  const { updateDocument, response } = useFirestore('USERS') 
  const { pongUID } = useParams()
  const { errorA, documentA } = useDocumentA('USERS', pongUID)
  const navigate = useNavigate()

  if (error) {return <div className="error">{error}</div>}
  if (!document) {return <div className="loading">Loading ...</div>}
  if (errorA) {return <div className="error">{errorA}</div>}
  if (!documentA) {return <div className="loading">Loading ...</div>}

  // yet to check that I do not send a request to myself!
  const handleSubmit = async (e) => {
    e.preventDefault()

    let arrPing = document.C // my contacts
    // if the pongUID is not already there as o i.e. other
    // Ping: iT o:uIDofPong d:nameOfPong e:emailOfPong t:timeStarted
    // m:message
    // yF
    if (arrPing.filter(e => e.o === pongUID).length === 0) {
      arrPing.push({
        t:  Date.now().toString(),
        i: true, // I pinged
        o: pongUID, // to this person
        d: documentA.d, // with name
        e: documentA.e, // and email
        y: false, // we both do not agree yet
        ri: false, // ping rescinds
        yo: false, // pong does not say yes
        no: false, // pong rejects
        xi: false, // ping blocks
        xo: false // pong blocks
      })
    }

    const updatesPing = { 
      C: arrPing // C Contacts array
    }

    let arrPong = documentA.C // contacts of Pong
    // if the my uID is not already there as o i.e. other
    if (arrPong.filter(e => e.o === user.uid).length === 0) {
      arrPong.push({
        t: Date.now().toString(),
        i: false, // I am pinged
        o: user.uid, // by this person
        d: document.d, // with name
        e: document.e, // and email
        y: false, // we both do not agree yet
        ri: false, // ping rescinds
        yo: false, // pong does not say yes
        no: false, // pong rejects
        xi: false, // ping blocks
        xo: false // pong blocks
      })
    }

    const updatesPong = { 
      C: arrPong // C Contacts array
    }

    // save updatesPing to my account
    await updateDocument(user.uid, updatesPing)
    // save updatesPong to Pong account
    await updateDocument(pongUID, updatesPong)
    if (!response.error) {
      navigate('/colleagues')
    }
  }

  return (
    <>
    
    <div className="flex justify-center font-body">
      <div>
          {documentA.d}
      </div>
      &nbsp;
      <div>
        {documentA.e}
      </div>
    </div>

    <div className="flex justify-center font-body">
      <form onSubmit={handleSubmit}>
        
        <div>
          <button 
            type='submit'
            className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
            >
              Send request
          </button>
        </div>
        </form>
    </div>
    
    </>
  )
}
