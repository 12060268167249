import { useState } from 'react'
import { useDocument } from '../../hooks/useDocument'
import { useFirestore } from '../../hooks/useFirestore';
import { useFirestoreA } from '../../hooks/useFirestoreA'
import { v4 as uuidv4 } from 'uuid';

import { useAuthContext } from '../../hooks/useAuthContext';
import { Link, useNavigate } from 'react-router-dom';

// create a knm block of type kType
// a block is created ONLY within a project by those who have r9/owner role
// the block is visible always to all members of WG in their respective roles
// a block can be made visible by r5 to any colleage/or even any registered user temporarily

// when a block is created, it is
// 1. created and placed at KNM Collection
// 2. registered at Projects Collection
// 3. registered at USERS Collection

// 1. KNM Collection
// a b c g t N V
// a : display title almost fixed
// c : creator uID FIXED forever
// g : type of block, in this case kType
// t : timestamp
// N : Nodes list
// V : list of users to which this block is visible in some form
// each element of V will have like the element of WG
// V: [{di ei do eo iUID oUID r1-r9 t}]
// here only external members that are not part of the WG

// type kType: 
// Xa Text readings of type a
// Gb Bibliography Books Articles
// Gc Catalogue of manuscripts
// Gd Catalogue of printed editions Druckausgabe

export default function ZCreateK({jID, kType}) {
  const { user } = useAuthContext()
  const { error, document } = useDocument('Projects', jID)
  const { setDocument, response } = useFirestore('KNM') 
  const { updateDocumentA, responseA } = useFirestoreA('Projects') 
  const navigate = useNavigate()
  const [title, setTitle] = useState('')
  const [q, setQ] = useState('00')

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading ...</div>
  }

  // 1. for knmX collection
  const handleSubmit = async (e) => {
    e.preventDefault()
    const kID = uuidv4()
    const payloadForKNM = {
      jID, // save the jID of the project it is part of
      kID, // kID of this block
      a:title, // title of the block for organisational purpose
      c:user.uid, // initator or creator fixed forever
      g:kType, // signature or type of block
      t:Date.now(), // time stamp created At
      N:[] // main axis, nodes 
    }
    
    // save at the KNM Collection
    await setDocument(kID, payloadForKNM)

    // 2. register at Projects Collection
    // kID, a, g
    // added to this q for sequencing
    // t for timestamp

    // register this block in the project
    let arr = document.KNM
    if (arr.filter(e => e.kID === kID).length === 0) {
      arr.push({
        kID, 
        a:title, // title of this block for organizational purposes
        g:kType, // signature or type of this block
        q,
        V:[], // V is list of users who can view this block
        t:Date.now() // time stamp created At
      })
    }

    const updatesA = { 
      KNM: arr // knmX blocks array
    }
    
    // Add it to the Projects list KNM of the project
    await updateDocumentA(jID, updatesA)

    // 3. register at USERS Collection
    // By default all users in project WG have access to this block
    // In case you want to hide it or show only to few, go to 
    // Manage block

    if (!response.error && !responseA.error) {
      navigate(`/projectoverview/${jID}`)
    }
  }

  return (
    <>
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">

          <div className="mt-10 text-center text-l font-mono leading-9 tracking-tight text-gray-900">
            Add a new block
          </div>
          <div className="mt-1 text-center text-l font-mono font-bold leading-9 tracking-tight text-gray-900">
            {kType}
          </div>
        </div>

        <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email" className="block text-xs ml-2 font-mono font-medium leading-6 text-gray-900">
                Short title of the block
              </label>
              <div className="mt-2">
                <input
                  id="title"
                  name="title"
                  type="text"
                  placeholder='Short title of the block'
                  autoComplete="title"
                  required
                  onChange={(e) => setTitle(e.target.value)} 
                  value={title}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-abhi-red sm:text-sm sm:leading-6"
                />
              </div>
              <div className="mt-2">
                <input
                  id="q"
                  name="q"
                  type="text"
                  placeholder='Sequence of the block'
                  autoComplete="q"
                  onChange={(e) => setQ(e.target.value)} 
                  value={q}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-abhi-red sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-abhi-red px-3 py-1.5 text-sm font-mono font-semibold leading-6 text-white shadow-sm hover:bg-abhi-link-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-abhi-red"
              >
                Add
              </button>
            </div>
            <div>
              {error && <div>{error}</div>}
            </div>
          </form>

          <p className="text-center font-mono text-xs text-gray-500">
            Block type: {kType}
          </p>
          <p className="text-center font-mono text-xs text-gray-500">
            <Link to="#" className="leading-6 text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4">
              More information about blocks
            </Link>
          </p>
        </div>
      </div>
    </>
  )
}
