import { useDocument } from "../../hooks/useDocument"
import { useNavigate, useParams } from "react-router-dom"

// here the email id is received
// check in EMAILuID if there is a corresponding entry
// if no, then go back to profile page with a caption
// this email is not registered
// if registered, but already invited for collaboration, then 
// go back to profile with the caption, 
// communication already in process
// otherwise goto next step 3
export default function CollaborationReq2() {
  const { email } = useParams()
  const { error, document } = useDocument('EMAILuID', email)
  const navigate = useNavigate()

  if (error) {return (<div className="loading">{email} &nbsp;does not exist!</div>)}
  if (!document) {return <div className="loading">Loading ...</div>}

  const handleSubmit = async (e) => {
    e.preventDefault()
    navigate(`/collaborationreq3/${document.uID}`)
  }

  return (
    <>
    <div className="flex justify-center font-body">
      <div>
          {document.d}
      </div>
      &nbsp;
      <div>
        {document.e}
      </div>
    </div>
    <div className="flex justify-center font-body">
      <form onSubmit={handleSubmit}>
        <div>
          <button 
            type='submit'
            className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
            >
              Send request
          </button>
        </div>
      </form>
    </div>
    </>
  )
}
