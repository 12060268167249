export default function ZWGList({wgList}) {

  // wgList = [{ },...]
  // uID: of the person
  // d: display name
  // e: email
  // t: time stamp

  return (
    <>
      {
        wgList && wgList.map(p => (
          <div key={p.oUID} className='flex font-mono text-sm pb-1 gap-1'>
            {
              p.do && 
              <div className='font-bold'>
                {p.do}
              </div>
            }
            <div className='text-slate-500'>
              {p.r9 && <span>Owner</span>}
              {!p.r9 && p.r7 && <span>Manager</span>}
              {!p.r9 && !p.r7 && p.r5 && <span>Admin</span>}
              {!p.r9 && !p.r7 && !p.r5 && p.r3 && <span>Editor</span>}
              {!p.r9 && !p.r7 && !p.r5 && !p.r3 && p.r1 && <span>Reader</span>}
            </div>
          </div>
        ))
      }
    </>
  )
}
