import { Link, useParams } from "react-router-dom"
import { useDocument } from "../../hooks/useDocument"
import { useEffect, useState } from "react"
import { useAuthContext } from "../../hooks/useAuthContext"

import ZWGList from "./ZWGList"
import ZInviteList from "./ZInviteList"
import ZNavbarProject from "./ZNavbarProject"
import ZKNMList from "../knm/ZKNMList"

export default function ProjectOverview() {
  const { user } = useAuthContext()
  const { jID } = useParams()
  const { error, document } = useDocument('Projects', jID)
  const [wgMember, setWgMember] = useState({})

  useEffect(() => {
    if (document && document.WG){ 
      let result = document.WG.filter(obj => {
        return obj.oUID === user.uid
      })
      setWgMember(result[0])
    }
  }, [document])

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading ...</div>
  }
 
  return (
    <>
      <ZNavbarProject jID={jID} project={document} />

      <div className='grid sm:grid-cols-3 py-3'>
        <div className="px-4 sm:px-12 sm:col-span-2 pb-5">
          <div className="flex justify-start">
            <div className="font-mono font-bold text-sm" >
              Blocks
            </div>
            {
              wgMember && wgMember.r7 && 
                <Link to={`/createk/${jID}`} className="pt-1 text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
                </Link>
            }
          </div>
          <div className="pt-1">
            <ZKNMList jID={jID} wgMember={wgMember} knmList={document.KNM} />
          </div>
        </div>
        <div className="px-4 sm:col-span-1">
          <div className="flex justify-start">
            <div className="font-mono font-bold text-sm" >
              Workgroup
            </div>
            {
              wgMember && wgMember.r7 && 
                <Link to={`/wginvitestep1/${jID}`} className="pt-1 text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4"
                >
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
                </Link>
            }
          </div>
          <div className='pt-1'>
            <ZWGList wgList={document.WG} />  
          </div>
          {/** only when there are open invitations */}
          {
            document.I && document.I.length > 0 && 
            document.I.filter(inv => !inv.y).length > 0 &&
            <div className='font-mono text-sm pt-5'>
              Invitation sent to
              <div className='font-mono pt-1'>
                <ZInviteList inviteList={document.I} />
              </div>
            </div>
          }
        </div>
      </div>
    </>
  )
}
