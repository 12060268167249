import { useDocument } from "../../hooks/useDocument"
import { useFirestore } from "../../hooks/useFirestore"
import { useEffect, useState } from "react"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useNavigate, useParams } from "react-router-dom"

export default function ProjectEditQ() {
  const { user } = useAuthContext()
  const { error, document } = useDocument('USERS', user.uid)
  const { jID } = useParams()
  const { updateDocument, response } = useFirestore('USERS') 
  const navigate = useNavigate()
  const [Q, setQ] = useState('')

  // J: Projects
  useEffect(() => {
    if (document && document.J){ 
      let result = document.J.filter(obj => {
        return obj.jID === jID
      })
      
      setQ(result[0].q)
    }
  }, [document])

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading ...</div>
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const newProjects = document.J.map( prj => {
      if (prj.jID === jID) {
        return {...prj, q:Q}
      } else {
        return {...prj}
      }
    })

    const updates = { 
      J: newProjects
    }

    await updateDocument(user.uid, updates)
    if (!response.error) {
      navigate('/workspace')
    }
  }

  return (
    <>

    <div className="flex justify-center font-mono">
      Edit Sequence
    </div>

    <div className="flex justify-center font-mono">
      <form onSubmit={handleSubmit}>
          <input 
              id="Q"
              type="text" 
              onChange={(e) => setQ(e.target.value)}
              value={Q}
          />
        <div>
          <button 
            type='submit'
            className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
            >
              Update
          </button>
        </div>
      </form>
    </div>
    </>
  )
}
