import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFirestore } from '../../hooks/useFirestore'
import AbhiNameWithLogo from '../../components/AbhiNameWithLogo'


export default function SetKNMJSON() {
  const { setDocument, response } = useFirestore('KNM') 
  const navigate = useNavigate()
  const [knm, setKnm] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    const jsonifiedKNM = JSON.parse(knm)
    console.log(jsonifiedKNM)
    
    await setDocument(jsonifiedKNM.id, jsonifiedKNM)
    if (!response.error) {
      navigate('/workspace')
    }
  }

  return (
    <>

      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <AbhiNameWithLogo />
          <div className="mt-10 text-center text-l font-mono leading-9 tracking-tight text-gray-900">
            Set KNM
          </div>
        </div>

        <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-sm">

        <form onSubmit={handleSubmit}>
          <div className="mt-2">
            <textarea 
              id="knm" 
              name="knm" 
              rows="7" 
              className="block p-3 w-full rounded-md border-0 py-1.5 font-mono font-semibold tracking-wide text-slate-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
              onChange={(e) => setKnm(e.target.value)}
              value={knm}
            >
            </textarea>
          </div>
          <div className='flex justify-between pt-3 px-3'>
          <label htmlFor="knm" className="block text-xs font-mono text-slate-500">Edit this passage (अ-a)</label>
            <button 
              type='submit'
              className='font-mono font-bold text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
            >
              Save
            </button>
          </div>
          </form>

      
        </div>
      </div>
    </>
  )
}