import { useState } from 'react'
import { useDocument } from '../../hooks/useDocument'
import { useFirestore } from '../../hooks/useFirestore';
import { Link, useNavigate } from 'react-router-dom';
import xForm from '../../hooks/xForm';

// create a KNM N node for block type Xa

// a node is created ONLY within a project/block by those who have r5/admin role
// the node is visible always to all members of WG in their respective roles
// a node is visible to any within preview to even any registered user temporarily

// when a node is created, it is
// 1. created and placed at N:[] array of block kID of KNM Collection

// we do not need node type as the type is set by block
// we have block types and not node types

// these fields will represent the flexibility of the system
// for sandhi anvaya anuvada translation explanatory notes critical comments

// Gb bibliographical entry, books, articles
// 1. N:[] array of the block kID of KNM Collection
// nID unique node ID also timestamp Date.now()
// q seq
// x main skt text hk
// y layer above hk
// z my notes

export default function ZCreateKNXa({jID, kID}) {

  const { error, document } = useDocument('KNM', kID)
  const { updateDocument, response } = useFirestore('KNM') 
  const navigate = useNavigate()
  const { xFormXa } = xForm()
  const [x, setX] = useState('') //
  const [q, setQ] = useState('00') //

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading ...</div>
  }

  const block = document

  // 1. for KNM collection
  // get the basic payload for new node

  const handleSubmit = async (e) => {
    e.preventDefault()
    // add this node in the block 
    let arr = document.N
    arr.push({
      nID: Date.now(), // save the nID of the project it is part of
      x, // 
      y:"", //
      z:"", //
      q, // sequencing
    })

    const updates = { 
      N: arr // N  array
    }
    
    // update the knmX block
    await updateDocument(kID, updates)

    if (!response.error) {
      navigate(`/knmoverview/${jID}/${kID}`)
    }
  }

  return (
    <>
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">

          <div className="mt-10 text-center text-l font-mono leading-9 tracking-tight text-gray-900">
            Add a new node
          </div>
          <div className="mt-1 text-center text-l font-mono font-bold leading-9 tracking-tight text-gray-900">
            {block.g}
          </div>
        </div>

        <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="x" className="block text-xs ml-2 font-mono font-medium leading-6 text-gray-900">
                x
              </label>

              <div className='mt-2'>
                <div className='font-body font-medium tracking-wide leading-8 text-slate-900'
                  dangerouslySetInnerHTML={{ __html: xFormXa(x) }} />
              </div>
              <div className="mt-2">
                <input
                  id="x"
                  name="x"
                  type="text"
                  placeholder='text in hk'
                  autoComplete="x"
                  onChange={(e) => setX(e.target.value)} 
                  value={x}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-abhi-red sm:text-sm sm:leading-6"
                />
              </div>
              <div className="mt-2">
                <input
                  id="q"
                  name="q"
                  type="text"
                  placeholder='Sequence of the node'
                  autoComplete="q"
                  onChange={(e) => setQ(e.target.value)} 
                  value={q}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-abhi-red sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-abhi-red px-3 py-1.5 text-sm font-mono font-semibold leading-6 text-white shadow-sm hover:bg-abhi-link-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-abhi-red"
              >
                Add
              </button>
            </div>
            <div>
              {error && <div>{error}</div>}
            </div>
          </form>

          <p className="text-center font-mono text-xs text-gray-500">
            Block type: {block.g}
          </p>
          <p className="text-center font-mono text-xs text-gray-500">
            <Link to="#" className="leading-6 text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4">
              More information about blocks
            </Link>
          </p>
        </div>
      </div>

    </>
  )
}
