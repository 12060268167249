import copy from "copy-to-clipboard"

import { useCallback, useEffect, useState } from "react"

export default function useCopyToClipboard(resetInterval = null) {
    const [isCopied, setCopied] = useState(false)

    const handleCopy = useCallback((text) => {
        if (typeof text === "string" || typeof text == "number") {
            copy(text.toString())
            setCopied(true)
        } else {
            setCopied(false)
            console.log('Cannot copy')
        }
    }, [])

    useEffect(() => {
        let timeout
        if (isCopied && resetInterval) {
            timeout = setTimeout(() => setCopied(false), resetInterval)
        }
        return () => {
            clearTimeout(timeout)
        }
    }, [isCopied, resetInterval])

  return [isCopied, handleCopy]
}
