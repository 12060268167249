import { Link, useParams } from "react-router-dom"
import { useDocument } from "../../hooks/useDocument"
import { useEffect, useState } from "react"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useDocumentA } from "../../hooks/useDocumentA"

import ZVList from "./ZVList"
import ZKNListXa from "./ZKNListXa"
import ZKNListGb from "./ZKNListGb"
import ZKNListGe from "./ZKNListGe"
import ZKNListZa from "./ZKNListZa"

export default function KNMOverview() {
  const { user } = useAuthContext()
  const { jID, kID } = useParams()
  const { error, document } = useDocument('Projects', jID)
  const { errorA, documentA } = useDocumentA('KNM', kID)
  const [wgMember, setWgMember] = useState({})
  const [vL, setVL] = useState([])

  useEffect(() => {
    if (document && document.WG){ 
      let result = document.WG.filter(obj => {
        return obj.oUID === user.uid
      })
      setWgMember(result[0])
    }
    if (document && document.KNM){ 
      let k = document.KNM.filter(obj => {
        return obj.kID === kID
      })
      setVL(k[0].V)
    }
  }, [document])

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading ...</div>
  }
  if (errorA) {
    return <div className="error">{errorA}</div>
  }
  if (!documentA) {
    return <div className="loading">Loading ...</div>
  }
  const project = document
  const block = documentA

  return (
    <>
      {/** Navbar for wgMember */}
      <div className="flex font-mono gap-1 text-xs ml-4 py-1">
      {wgMember &&
      <>
        <>
          <Link to={`/projectoverview/${jID}`} className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'>
            {project.a}
          </Link>
          <div>
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.18194 4.18185C6.35767 4.00611 6.6426 4.00611 6.81833 4.18185L9.81833 7.18185C9.90272 7.26624 9.95013 7.3807 9.95013 7.50005C9.95013 7.6194 9.90272 7.73386 9.81833 7.81825L6.81833 10.8182C6.6426 10.994 6.35767 10.994 6.18194 10.8182C6.0062 10.6425 6.0062 10.3576 6.18194 10.1819L8.86374 7.50005L6.18194 4.81825C6.0062 4.64251 6.0062 4.35759 6.18194 4.18185Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
          </div>
          <Link to={`/knmoverview/${jID}/${kID}`} className='font-bold text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'>
            {block.a}
          </Link>
          <div>
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.18194 4.18185C6.35767 4.00611 6.6426 4.00611 6.81833 4.18185L9.81833 7.18185C9.90272 7.26624 9.95013 7.3807 9.95013 7.50005C9.95013 7.6194 9.90272 7.73386 9.81833 7.81825L6.81833 10.8182C6.6426 10.994 6.35767 10.994 6.18194 10.8182C6.0062 10.6425 6.0062 10.3576 6.18194 10.1819L8.86374 7.50005L6.18194 4.81825C6.0062 4.64251 6.0062 4.35759 6.18194 4.18185Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
          </div>
        </>
        
      </>
      }
      </div>
      {/** Navbar ends here */}

      <div className="flex justify-start px-4 sm:px-12">
        <div className="font-mono font-bold text-sm" >
          {block.g} Nodes
        </div>
        {
          wgMember && wgMember.r5 && 
            <Link to={`/createkn/${jID}/${kID}`} className="pt-1 text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4">
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
            </Link>
        }
      </div>

      {
        block.g === "Gb" && 
        <div className="pt-1">
          <ZKNListGb jID={jID} kID={kID} knList={block.N} />
        </div>
      }
      {
        block.g === "Ge" && 
        <div className="pt-1">
          <ZKNListGe jID={jID} kID={kID} knList={block.N} />
        </div>
      }
      {
        block.g === "Xa" &&
        <div className="pt-1">
          <ZKNListXa jID={jID} kID={kID} knList={block.N} />
        </div>
      }
      {
        block.g === "Za" &&
        <div className="pt-1">
          <ZKNListZa jID={jID} kID={kID} knList={block.N} />
        </div>
      }
      

      <div className='grid sm:grid-cols-1 py-5'>
        <div className="px-4 sm:px-12 sm:col-span-1 pb-5">

          <div className="flex justify-start">
            <div className="font-mono font-bold text-sm" >
              Visible to 
            </div>
            {
              wgMember && wgMember.r7 && 
                <Link to={`/knmpreviewrelease1/${jID}/${kID}`} className="pt-1 text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4"
                >
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
                </Link>
            }
          </div>
          <div className='pt-1'>
            {/** check if oUID of any V entry is in WG of the block project
             * then filter them
             */}
            <ZVList vList={vL} />  
          </div>
         
        </div>
        </div>
      
    </>
  )
}
