export default function ZInviteList({inviteList}) {

  // inviteList = [{ },...]
  // t timestamp
  // jID id of the project
  // a: display name title of the project
  // iUID ping uID the person sending the invite
  // di: display name of the person
  // ei: email
  // oUID pong uID the person being invited
  // do: name
  // eo: email
  // y: yes accepted
  // n: no declined
  // r9-r1: t/f roles he is supposed to do

  return (
    <>
      {
        inviteList && inviteList.map(inv => (
          <div key={inv.oUID} >
            {
              !inv.y && 
              <div className='font-mono pb-3'>
                <div className='font-bold text-sm'>
                  {inv.do} 
                </div>
                <div className='text-xs text-slate-500 '>
                  {inv.r9 && <span><b>Owner</b></span>}
                  {!inv.r9 && inv.r7 && <span><b>Manager</b></span>}
                  {!inv.r9 && !inv.r7 && inv.r5 && <span><b>Admin</b></span>}
                  {!inv.r9 && !inv.r7 && !inv.r5 && inv.r3 && <span><b>Editor</b></span>}
                  {!inv.r9 && !inv.r7 && !inv.r5 && !inv.r3 && inv.r1 && <span><b>Reader</b></span>}
                </div>

                <div className='text-xs text-slate-500'>
                  by {inv.di}
                </div>
                {inv.n && 
                  <div className='text-sm font-bold'> 
                    Declined!
                  </div>
                }
              </div>
            }
          </div>
        ))
      }
    </>
  )
}