import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useDocument } from "../../hooks/useDocument";
import { useDocumentA } from "../../hooks/useDocumentA";
import { useFirestore } from "../../hooks/useFirestore";

// yF
// riT yoF noT xiF xoF 06: iRecall oNo
//  =iT revoke->05 stop->10
//  =iF accept->04 stop->12

export default function GoToStage06() {
  // I am the user.uid 
  // in my contact.o is the uID of the other one
  const { user } = useAuthContext()
  const { error, document } = useDocument('USERS', user.uid)
  // oUID is uID of the other one
  const { oUID } = useParams()
  // documentA is the account of the other one
  const { errorA, documentA } = useDocumentA('USERS', oUID)
  const { updateDocument, response } = useFirestore('USERS')
  const navigate = useNavigate()

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading ...</div>
  }
  if (errorA) {
    return <div className="error">{errorA}</div>
  }
  if (!documentA) {
    return <div className="loading">Loading ...</div>
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    // update my account
    const updatedArrMy = document.C.map((contact) => 
      contact.o === oUID ? { ...contact, ...{
        y:false,
        ri:true,
        yo:false,
        no:true,
        xi:false,
        xo:false
      }} : contact
    )

    const updatesMy = { 
      C: updatedArrMy // C Contacts array
    }

    // Other persons acc is documentA
    const updatedArrOther = documentA.C.map((contact) => 
      contact.o === user.uid ? { ...contact, ...{
        y:false,
        ri:true,
        yo:false,
        no:true,
        xi:false,
        xo:false
      }} : contact
    )

    const updatesOther = { 
      C: updatedArrOther // C Contacts array
    }

    // save updatesMy to my account
    await updateDocument(user.uid, updatesMy)
    // save updatesOther to other account
    await updateDocument(oUID, updatesOther)
    if (!response.error) {
      navigate('/profile')
    }
  }

  return (
    <>
    <div className="flex justify-center font-body">
      <div>
        {documentA.d}
        &nbsp;
        {documentA.e}
      </div>
    </div>

    <div className="flex justify-center font-body">
      <form onSubmit={handleSubmit}>
        <div>
          <button 
            type='submit'
            className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
            >
              Go to 6
          </button>
        </div>
      </form>
    </div>
    </>
  )
}