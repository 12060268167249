import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'

// pages
import Home from './pages/home/Home'

import Navbar from './components/Navbar'
import NavbarUser from './components/NavbarUser'

import Workspace from './pages/workspace/Workspace'
import Login from './pages/zugang/Login'
import Signup from './pages/zugang/Signup'
import Support from './pages/support/Support'
import GetStarted from './pages/support/GetStarted'
import SupportEditorial from './pages/support/SupportEditorial'
import SupportTechnical from './pages/support/SupportTechnical'
import Profile from './pages/profile/Profile'
import Readings from './pages/readings/Readings'

import CollaborationReq1 from './pages/colleagues/CollaborationReq1'
import CollaborationReq2 from './pages/colleagues/CollaborationReq2'
import CollaborationReq3 from './pages/colleagues/CollaborationReq3'
import GoToStage01 from './pages/colleagues/GoToStage01'
import GoToStage02 from './pages/colleagues/GoToStage02'
import GoToStage03 from './pages/colleagues/GoToStage03'
import GoToStage04 from './pages/colleagues/GoToStage04'
import GoToStage05 from './pages/colleagues/GoToStage05'
import GoToStage06 from './pages/colleagues/GoToStage06'
import GoToStage07 from './pages/colleagues/GoToStage07'
import GoToStage08 from './pages/colleagues/GoToStage08'
import GoToStage09 from './pages/colleagues/GoToStage09'
import GoToStage10 from './pages/colleagues/GoToStage10'
import GoToStage11 from './pages/colleagues/GoToStage11'
import GoToStage12 from './pages/colleagues/GoToStage12'
import GoToStage13 from './pages/colleagues/GoToStage13'
import GoToStage14 from './pages/colleagues/GoToStage14'

import ProjectOverview from './pages/projects/ProjectOverview'
import WGInviteStep1 from './pages/projects/WGInviteStep1'
import WGInviteStep2 from './pages/projects/WGInviteStep2'
import WGInviteStep4 from './pages/projects/WGInviteStep4'
import WGInviteStep3 from './pages/projects/WGInviteStep3'
import ProjectEditQ from './pages/projects/ProjectEditQ'

import ProjectInitiateInfo from './pages/support/ProjectInitiateInfo'

import KNMOverview from './pages/knm/KNMOverview'
import KNMPreviewRelease1 from './pages/knm/KNMPreviewRelease1'
import KNMPreviewRelease2 from './pages/knm/KNMPreviewRelease2'
import KNMPreviewRelease3 from './pages/knm/KNMPreviewRelease3'
import GetKNMJSON from './pages/knm/GetKNMJSON'
import SetKNMJSON from './pages/knm/SetKNMJSON'
import InitiateProject from './pages/projects/InitiateProject'

import KNMEditNq from './pages/knm/KNMEditNq'

import CreateK from './pages/knm/CreateK'
import CreateKN from './pages/knm/CreateKN'
import KNViewXa from './pages/knm/KNViewXa'
import KNViewGb from './pages/knm/KNViewGb'
import KNViewGe from './pages/knm/KNViewGe'
import KNAssignNEID from './pages/knm/KNAssignNEID'
import KNViewZa from './pages/knm/KNViewZa'
import KNMEditq from './pages/knm/KNMEditq'

function App() {
  const { authIsReady, user } = useAuthContext()

  return (
    <div className="App">
      { authIsReady && (
        <BrowserRouter>
          {!user && <Navbar />}
          {user && <NavbarUser />}
          <div>
            <Routes>
              <Route path='/' element={ <Home /> } />

              <Route path='/support' element={ <Support /> } />
              <Route path='/getstarted' element={ <GetStarted /> } />
              <Route path='/supporteditorial' element={ <SupportEditorial /> } />
              <Route path='/supporttechnical' element={ <SupportTechnical /> } />
              
              <Route path='/readings' element={ <Readings /> } />


              <Route path='/login' element={user ? <Navigate to="/workspace" /> : <Login />} />
              {/*
              <Route path='/signup' element={user ? <Navigate to="/workspace" /> : <Signup />} />
              */}
              <Route path='/profile' element={user ? <Profile /> : <Navigate to="/login" />} />

              <Route path='/collaborationreq1' element={user ? <CollaborationReq1 /> : <Navigate to="/login" />} />
              <Route path='/collaborationreq2/:email' element={user ? <CollaborationReq2 /> : <Navigate to="/login" />} />
              <Route path='/collaborationreq3/:pongUID' element={user ? <CollaborationReq3 /> : <Navigate to="/login" />} />

              <Route path='/gotostage01/:oUID' element={user ? <GoToStage01 /> : <Navigate to="/login" />} />
              <Route path='/gotostage02/:oUID' element={user ? <GoToStage02 /> : <Navigate to="/login" />} />
              <Route path='/gotostage03/:oUID' element={user ? <GoToStage03 /> : <Navigate to="/login" />} />
              <Route path='/gotostage04/:oUID' element={user ? <GoToStage04 /> : <Navigate to="/login" />} />
              <Route path='/gotostage05/:oUID' element={user ? <GoToStage05 /> : <Navigate to="/login" />} />
              <Route path='/gotostage06/:oUID' element={user ? <GoToStage06 /> : <Navigate to="/login" />} />
              <Route path='/gotostage07/:oUID' element={user ? <GoToStage07 /> : <Navigate to="/login" />} />
              <Route path='/gotostage08/:oUID' element={user ? <GoToStage08 /> : <Navigate to="/login" />} />
              <Route path='/gotostage09/:oUID' element={user ? <GoToStage09 /> : <Navigate to="/login" />} />
              <Route path='/gotostage10/:oUID' element={user ? <GoToStage10 /> : <Navigate to="/login" />} />
              <Route path='/gotostage11/:oUID' element={user ? <GoToStage11 /> : <Navigate to="/login" />} />
              <Route path='/gotostage12/:oUID' element={user ? <GoToStage12 /> : <Navigate to="/login" />} />
              <Route path='/gotostage13/:oUID' element={user ? <GoToStage13 /> : <Navigate to="/login" />} />
              <Route path='/gotostage14/:oUID' element={user ? <GoToStage14 /> : <Navigate to="/login" />} />

              <Route path='/workspace' element={user ? <Workspace /> : <Navigate to="/login" />} />

              <Route path='/projectinitiateinfo' element={user ? <ProjectInitiateInfo /> : <Navigate to="/login" />} />
              <Route path='/initiateproject' element={user ? <InitiateProject /> : <Navigate to="/login" />} />

              <Route path='/projectoverview/:jID' element={user ? <ProjectOverview /> : <Navigate to="/login" />} />

              <Route path='/projecteditq/:jID' element={user ? <ProjectEditQ /> : <Navigate to="/login" />} />

              <Route path='/wginvitestep1/:jID' element={user ? <WGInviteStep1 /> : <Navigate to="/login" />} />
              <Route path='/wginvitestep2/:jID/:oUID' element={user ? <WGInviteStep2 /> : <Navigate to="/login" />} />
              <Route path='/wginvitestep3/:jID' element={user ? <WGInviteStep3 /> : <Navigate to="/login" />} />
              <Route path='/wginvitestep4/:jID' element={user ? <WGInviteStep4 /> : <Navigate to="/login" />} />

              <Route path='/knmoverview/:jID/:kID' element={user ? <KNMOverview /> : <Navigate to="/login" />} />
              <Route path='/knmeditq/:jID/:kID' element={user ? <KNMEditq /> : <Navigate to="/login" />} />
              <Route path='/knmpreviewrelease1/:jID/:kID' element={user ? <KNMPreviewRelease1 /> : <Navigate to="/login" />} />
              <Route path='/knmpreviewrelease2/:jID/:kID/:email' element={user ? <KNMPreviewRelease2 /> : <Navigate to="/login" />} />
              <Route path='/knmpreviewrelease3/:jID/:kID/:oUID' element={user ? <KNMPreviewRelease3 /> : <Navigate to="/login" />} />
              
              <Route path='/createk/:jID' element={user ? <CreateK /> : <Navigate to="/login" />} />
              <Route path='/createkn/:jID/:kID' element={user ? <CreateKN /> : <Navigate to="/login" />} />
              
              <Route path='/knviewxa/:jID/:kID/:nID' element={user ? <KNViewXa /> : <Navigate to="/login" />} />
              <Route path='/knviewza/:jID/:kID/:nID' element={user ? <KNViewZa /> : <Navigate to="/login" />} />
              <Route path='/knviewgb/:jID/:kID/:nID' element={user ? <KNViewGb /> : <Navigate to="/login" />} />
              <Route path='/knviewge/:jID/:kID/:nID' element={user ? <KNViewGe /> : <Navigate to="/login" />} />
              <Route path='/knmeditnq/:jID/:kID/:nID' element={user ? <KNMEditNq /> : <Navigate to="/login" />} />
              <Route path='/knassignneid/:jID/:kID/:nID' element={user ? <KNAssignNEID /> : <Navigate to="/login" />} />
              
              <Route path='/getknmjson/:kID' element={user ? <GetKNMJSON /> : <Navigate to="/login" />} />
              <Route path='/setknmjson' element={user ? <SetKNMJSON /> : <Navigate to="/login" />} />

            </Routes>
          </div>
        </BrowserRouter>
      )}
    </div>
  )
}

export default App;
