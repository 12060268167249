import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useDocument } from "../../hooks/useDocument";
import { useDocumentA } from "../../hooks/useDocumentA";
import { useFirestore } from "../../hooks/useFirestore";
import { useFirestoreA } from "../../hooks/useFirestoreA";

// reject
// I.n : true
export default function WGInviteStep3() {
  const { user } = useAuthContext()
  const { jID } = useParams()

  const { error, document } = useDocument('USERS', user.uid)
  const { updateDocument, response } = useFirestore('USERS')
  // documentA is the project
  const { errorA, documentA } = useDocumentA('Projects', jID)
  const { updateDocumentA, responseA } = useFirestoreA('Projects')
  const navigate = useNavigate()

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading ...</div>
  }
  if (errorA) {
    return <div className="error">{errorA}</div>
  }
  if (!documentA) {
    return <div className="loading">Loading ...</div>
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    // update my account
    const updatedArrMy = document.I.map((inv) => 
      inv.jID === jID ? { ...inv, ...{
        n:true,
        y:false
      }} : inv
    )

    const updatesMy = { 
      I: updatedArrMy // I Invite array
    }

    // update project entry
    const updatedArrIProj = documentA.I.map((inv) => 
      inv.oUID === user.uid ? { ...inv, ...{
        n:true,
        y:false
      }} : inv
    )

    const updatesProj = { 
      I: updatedArrIProj // I Invite array
    }

    // save updatesMy to my account
    await updateDocument(user.uid, updatesMy)
    // save updatesProj to proj
    await updateDocumentA(jID, updatesProj)
    if (!response.error && !responseA.error) {
      navigate('/workspace')
    }
  }

  return (
    <>
    <div className="flex justify-center font-mono">
      <div>
        {jID}
      </div>
    </div>

    <div className="flex justify-center font-mono">
      <form onSubmit={handleSubmit}>
        <div>
          <button 
            type='submit'
            className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
            >
              decline
          </button>
        </div>
      </form>
    </div>
    </>
  )
}
