import { useState, useEffect } from "react"
import { toast } from 'react-toastify'
import { useDocument } from "../../hooks/useDocument"
import { useFirestore } from "../../hooks/useFirestore"
import 'react-toastify/dist/ReactToastify.css'

// Edit the field eID of the node of block of type Gb
// eID is fixed use this only rarely
export default function ZEditKNeID({kID, nID}) {

  const { error, document } = useDocument('KNM', kID)
  const { updateDocument, response } = useFirestore('KNM') 

  const [N, setN] = useState([])
  const [eID, setEID] = useState('')
  const notify = () => toast("Updated!")

  useEffect(() => {
    if (document){
      setN(document.N)
      const selectedN = document.N.filter(nde => nde.nID == nID)
      if (selectedN.length > 0) {
        setEID(selectedN[0].eID)
      }
    }
  }, [document])

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading ...</div>
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const updatedN = N.map((nde) => 
    nde.nID == nID ? { ...nde, ...{
        eID,
        //"lastEdited":Date.now().toString()
      }} : nde
    )
    const updates = { 
      N: updatedN
    }
    await updateDocument(kID, updates)
    if (!response.error) {
      notify()
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="mt-2">
          <input
            id="editEID"
            name="editEID"
            type="text"
            placeholder="eID"
            onChange={(e) => setEID(e.target.value)} 
            value={eID}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-abhi-red sm:text-sm sm:leading-6"
          />
        </div>
        <div className='flex justify-end pt-1 px-3'>
          <button 
            type='submit'
            className='font-mono text-xs text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
          >
            Save
          </button>
        </div>
      </form>
    </>
  )
}