import { Link } from "react-router-dom";

// o d e t 
// i
// y
// ri yo no xi xo
// iT Ping, iF Pong 
// yT Collaboration
// yF 
// ri yo no xi xo

export default function ZColleagueProfile({contact}) {

  return (
    <div className='font-mono py-3'>
    
    <div className='flex justify-start text-sm '>
      {!contact.i && 
      <>
        <div className='text-slate-700'>
          &#x2598;
        </div>
        <div className='font-semibold'>
          {contact.d}
        </div>
      </>
      }
      {contact.i && 
      <>
        <div className='text-slate-400'>
          &#x2598;
        </div>
        <div className='font-semibold'>
          {contact.d}
        </div>
      </>
      }
    </div>
    <div className="justify-start font-mono text-xs pl-2">
      <div>{contact.e}</div>
          {/** 
           * 00: 
           * yT 
           * riF yoT noF xiF xoF Collaboration. 
           * riF yoT noF xiF xoF 03: iOpen oYes => yT Collaboration
           * =iT stop->07
           * =iF stop->11
           * */}

          {
            contact.y && 
            !contact.ri && 
            contact.yo && 
            !contact.no && 
            !contact.xi && 
            !contact.xo && 
            <>
            <div className='font-semibold'> 
              We collaborate 
            </div>
            <div>
              {
                contact.i && 
                <Link to={`/gotostage07/${contact.o}`}
                  className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
                  >
                    End this collaboration
                </Link>
              }
              {
                !contact.i && 
                <Link to={`/gotostage11/${contact.o}`}
                className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
                >
                    End this collaboration
                </Link>
              }
            </div>
            </>
          }

          {/** 
           * yF 
           * riF yoF noF xiF xoF 01: initial request
           * =iT recall->02 stop->07
           * =iF accept->03 reject->05 stop->11
          */}

          {
            !contact.y && 
            !contact.ri && 
            !contact.yo && 
            !contact.no && 
            !contact.xi && 
            !contact.xo && 
            <>
              {
                contact.i && 
                <>
                <div className='font-semibold'> 
                  I have sent a request
                </div>
                <div>
                  <Link to={`/gotostage02/${contact.o}`}
                    className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
                    >
                      Revoke
                  </Link>
                  &nbsp;|&nbsp;
                  <Link to={`/gotostage07/${contact.o}`}
                    className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
                  >
                    Stop any communication
                  </Link>
                </div>
                </>
              }
              {
                !contact.i && 
                <span>
                  <b>{contact.d}</b> sends a request |&nbsp;
                  <Link to={`/gotostage03/${contact.o}`}>
                      Accept
                  </Link>
                  &nbsp;|&nbsp;
                  <Link to={`/gotostage05/${contact.o}`}>
                      Reject
                  </Link>
                  &nbsp;|&nbsp;
                  <Link to={`/gotostage11/${contact.o}`}>
                      Stop any communication
                  </Link>
                </span>
              }
            </>
          }

          {/**
           * yF 
           * riT yoF noF xiF xoF 02: iRecall oUndecided
           * =iT recall->01 stop->08
           * =iF accept->04 reject->06 stop->12
           */}

          {
            !contact.y && 
            contact.ri && 
            !contact.yo && 
            !contact.no && 
            !contact.xi && 
            !contact.xo && 
            <>
              {
                contact.i && 
                <>
                <div className='font-semibold'> 
                  I sent a request but took it back
                </div>
                <div>
                  <Link to={`/gotostage01/${contact.o}`}
                    className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
                  >
                      Request again
                  </Link>
                  &nbsp;|&nbsp;
                  <Link to={`/gotostage08/${contact.o}`}
                    className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
                  >
                      Stop any communication
                  </Link>
                </div>
                </>
              }
              {
                !contact.i && 
                <span>
                  sent a request but then took it back |&nbsp;
                  <Link to={`/gotostage04/${contact.o}`}>
                      Accept
                  </Link>
                  &nbsp;|&nbsp;
                  <Link to={`/gotostage06/${contact.o}`}>
                      Reject
                  </Link>
                  &nbsp;|&nbsp;
                  <Link to={`/gotostage12/${contact.o}`}>
                      Stop any communication
                  </Link>
                </span>
              }
            </>
          }


          {/**
           * yF 
           * riT yoT noF xiF xoF 04: iRecall oYes
           * =iT recall->03 stop->09
           * =iF nowreject->06 stop->12
           */}

          {
            !contact.y && 
            contact.ri && 
            contact.yo && 
            !contact.no && 
            !contact.xi && 
            !contact.xo && 
            <span>
              {
                contact.i && 
                <span>
                  I sent a request but took it back |&nbsp;
                  {contact.d} has accepted my request
                  &nbsp;|&nbsp;
                  <Link to={`/gotostage03/${contact.o}`}>
                      Agree and collaborate
                  </Link>
                  &nbsp;|&nbsp;
                  <Link to={`/gotostage09/${contact.o}`}>
                      Stop any communication
                  </Link>
                </span>
                
              }
              {
                !contact.i && 
                <span>
                  sent a request but then took it back |&nbsp;
                  I however accepted it |&nbsp;
                  <Link to={`/gotostage06/${contact.o}`}>
                      Reject now
                  </Link>
                  &nbsp;|&nbsp;
                  <Link to={`/gotostage12/${contact.o}`}>
                      Stop any communication
                  </Link>
                </span>
              }
            </span>
          }

          {/**
           * yF 
           * riF yoF noT xiF xoF 05: iOpen oNo
           * =iT recall->06 stop->10
           * =iF accept->03 stop->11
           */}

          {
            !contact.y && 
            !contact.ri && 
            !contact.yo && 
            contact.no && 
            !contact.xi && 
            !contact.xo && 
            <span>
              {
                contact.i && 
                <span>
                  I have sent a request |&nbsp;
                  but <b>{contact.d}</b> has rejected my request |&nbsp;
                  <Link to={`/gotostage06/${contact.o}`}>
                      I also take back my request
                  </Link>
                  &nbsp;|&nbsp;
                  <Link to={`/gotostage10/${contact.o}`}>
                      Stop any communication
                  </Link>
                </span>
                
              }
              {
                !contact.i && 
                <span>
                  sent a request |&nbsp;
                  I however rejected it |&nbsp;
                  <Link to={`/gotostage03/${contact.o}`}>
                      Accept now
                  </Link>
                  &nbsp;|&nbsp;
                  <Link to={`/gotostage11/${contact.o}`}>
                      Stop any communication
                  </Link>
                </span>
              }
            </span>
          }


          {/**
           * yF 
           * riT yoF noT xiF xoF 06: iRecall oNo
           * =iT revoke->05 stop->10
           * =iF accept->04 stop->12
           */}

          {
            !contact.y && 
            contact.ri && 
            !contact.yo && 
            contact.no && 
            !contact.xi && 
            !contact.xo && 
            <span>
              {
                contact.i && 
                <span>
                  I had sent a request but later took it back |&nbsp;
                  and <b>{contact.d}</b> has rejected my request |&nbsp;
                  <Link to={`/gotostage05/${contact.o}`}>
                      I keep my request open
                  </Link>
                  &nbsp;|&nbsp;
                  <Link to={`/gotostage10/${contact.o}`}>
                      Stop any communication
                  </Link>
                </span>
                
              }
              {
                !contact.i && 
                <span>
                  sent a request but later took it back |&nbsp;
                  I also rejected it
                  &nbsp;|&nbsp;
                  <Link to={`/gotostage04/${contact.o}`}>
                      Accept now
                  </Link>
                  &nbsp;|&nbsp;
                  <Link to={`/gotostage12/${contact.o}`}>
                      Stop any communication
                  </Link>
                </span>
              }
            </span>
          }

          {/**
           * yF 
           * riF yoF noF xiT xoF 07: iOpen oUndecided iStopped
           * =iT restart->01
           * =iF 
           */}

          {
            !contact.y && 
            !contact.ri && 
            !contact.yo && 
            !contact.no && 
            contact.xi && 
            !contact.xo && 
            <>
              {
                contact.i && 
                <>
                <div className='font-semibold'>
                  I had sent a request but later stopped any communication |&nbsp;
                  <b>{contact.d}</b> is undecided |&nbsp;
                </div>
                <div>
                  <Link to={`/gotostage01/${contact.o}`}
                    className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
                  >
                      I request again
                  </Link>
                </div>
                </>
              }
              {
                !contact.i && 
                <span>
                  sent a request but later stopped any communication |&nbsp;
                  I was undecided and will decide in case <b>{contact.d}</b> requests again
                </span>
              }
            </>
          }


          {/**
           * yF 
           * riT yoF noF xiT xoF 08: iRecall oUndecided iStopped
           * =iT restart->01
           * =iF 
           */}

          {
            !contact.y && 
            contact.ri && 
            !contact.yo && 
            !contact.no && 
            contact.xi && 
            !contact.xo && 
            <span>
              {
                contact.i && 
                <>
                  <div className='font-semibold'>
                    I had sent a request but later recalled it and even stopped any communication |&nbsp;
                    {contact.d} is undecided
                  </div>
                  <div>
                  <Link to={`/gotostage01/${contact.o}`}
                    className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
                  >
                    <span className="animated-link">
                      I request again
                    </span>
                  </Link>
                  </div>
                </>
              }
              {
                !contact.i && 
                <span>
                  sent a request but later recalled and stopped any communication |&nbsp;
                  I was undecided and will decide in case <b>{contact.d}</b> requests again
                </span>
              }
            </span>
          }


          {/**
           * yF 
           * riT yoT noF xiT xoF 09: iRecall oYes iStopped
           * =iT revoke and collaborate->03
           * =iF 
           */}

          {
            !contact.y && 
            contact.ri && 
            contact.yo && 
            !contact.no && 
            contact.xi && 
            !contact.xo && 
            <span>
              {
                contact.i && 
                <span>
                  I had sent a request but later recalled it and even stopped any communication |&nbsp;
                  <b>{contact.d}</b> had accepted my request |&nbsp;
                  <Link to={`/gotostage03/${contact.o}`}>
                    <span className="animated-link">
                      I now collaborate
                    </span>
                  </Link>
                </span>
              }
              {
                !contact.i && 
                <span>
                  had sent a request but later recalled and stopped any communication |&nbsp;
                  I however accepted and a collaboration may result in case <b>{contact.d}</b> is ready and takes the initiative
                </span>
              }
            </span>
          }


          {/**
           * yF 
           * riT yoF noT xiT xoF 10: iRecall oNo iStopped
           * =iT restart->06 
           * =iF 
           */}

          {
            !contact.y && 
            contact.ri && 
            !contact.yo && 
            contact.no && 
            contact.xi && 
            !contact.xo && 
            <span>
              {
                contact.i && 
                <span>
                  I had sent a request but later recalled it and even stopped any communication |&nbsp;
                  <b>{contact.d}</b> has also rejected my request |&nbsp;
                  <Link to={`/gotostage06/${contact.o}`}>
                      I try a restart
                  </Link>
                </span>
              }
              {
                !contact.i && 
                <span>
                  had sent a request but later recalled and stopped any communication
                  &nbsp;|&nbsp;
                  I also rejected and in case <b>{contact.d}</b> tries again, I may consider further
                </span>
              }
            </span>
          }


          {/**
           * yF 
           * riF yoF noT xiF xoT 11: iOpen oNo oStopped
           * =iT 
           * =iF restart->01
           */}

          {
            !contact.y && 
            !contact.ri && 
            !contact.yo && 
            contact.no && 
            !contact.xi && 
            contact.xo && 
            <span>
              {
                contact.i && 
                <span>
                  I had sent a request |&nbsp;
                  <b>{contact.d}</b> rejected my request and has stopped any communication |&nbsp;
                  In case <b>{contact.d}</b> tries again, I may consider further
                </span>
              }
              {
                !contact.i && 
                <span>
                  had sent a request |&nbsp;
                  I rejected and stopped any communication |&nbsp;
                  <Link to={`/gotostage01/${contact.o}`}>

                      I try a restart

                  </Link>
                </span>
              }
            </span>
          }


          {/**
           * yF 
           * riT yoF noT xiF xoT 12: iRecall oNo ididintStop oStopped
           * =iT 
           * =iF restart->02
           */}

          {
            !contact.y && 
            contact.ri && 
            !contact.yo && 
            contact.no && 
            !contact.xi && 
            contact.xo && 
            <span>
              {
                contact.i && 
                <span>
                  I had sent a request but later took it back |&nbsp;
                  <b>{contact.d}</b> rejected my request and has stopped any communication |&nbsp;
                  In case <b>{contact.d}</b> tries again, I may consider further
                </span>
              }
              {
                !contact.i && 
                <span>
                  had sent a request but later took it back |&nbsp;
                  I rejected and stopped any communication |&nbsp;
                  <Link to={`/gotostage02/${contact.o}`}>
                      I try a restart
                  </Link>
                </span>
              }
            </span>
          }
          

          {/**
           * yF 
           * riF yoF noT xiT xoT 13: iOpen oNo iStopped oStopped
           * =iT 
           * =iF restart->07
           */}

          {
            !contact.y && 
            !contact.ri && 
            !contact.yo && 
            contact.no && 
            contact.xi && 
            contact.xo && 
            <span>
              {
                contact.i && 
                <span>
                  I had sent a request but later stopped any communication |&nbsp;
                  <b>{contact.d}</b> rejected my request and has stopped any communication
                  &nbsp;|&nbsp;
                  It is upto <b>{contact.d}</b> to take the initiative
                </span>
              }
              {
                !contact.i && 
                <span>
                  had sent a request but later stopped any communication |&nbsp;
                  I rejected and also stopped any communication |&nbsp;
                  <Link to={`/gotostage07/${contact.o}`}>

                      I restart a communication

                  </Link>
                </span>
              }
            </span>
          }


          {/**
           * yF 
           * riT yoF noT xiT xoT 14: iRecall oNo oStopped
           * =iT 
           * =iF restart->08
           */}

          {
            !contact.y && 
            contact.ri && 
            !contact.yo && 
            contact.no && 
            contact.xi && 
            contact.xo && 
            <span>
              {
                contact.i && 
                <span>
                  I had sent a request but later took it back and stopped any communication |&nbsp;
                  <b>{contact.d}</b> rejected my request and has stopped any communication |&nbsp;
                  It is upto <b>{contact.d}</b> to take the initiative
                </span>
              }
              {
                !contact.i && 
                <span>
                  had sent a request but later took it back and stopped any communication |&nbsp;
                  I rejected and also stopped any communication |&nbsp;
                  <Link to={`/gotostage08/${contact.o}`}>

                      I restart a communication

                  </Link>
                </span>
              }
            </span>
          }
    </div>
    </div>
  )
}
