import { useDocument } from "../../hooks/useDocument"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'

// first an email id is inputted
// this will be passed on to check if that person is a registered user
// this is done in step 2
export default function CollaborationReq1() {
  const { user } = useAuthContext()
  const { error, document } = useDocument('USERS', user.uid)
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const notify = () => toast("You are already there!")

  if (error) {return <div className="error">{error}</div>}
  if (!document) {return <div className="loading">Loading ...</div>}

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (user.email === email) {
      notify()
      navigate('/profile')
    } else {
      navigate(`/collaborationreq2/${email}`)
    }
  }

  return (
    <>
    <div className="flex justify-center bg-white font-body">
      <form onSubmit={handleSubmit}>

        <div>
          <label for='email'>Email</label>
          <div className='mt-2'>
            <input 
              className='' 
              id="email" 
              placeholder="name@example.com"
              type="email" 
              onChange={(e) => setEmail(e.target.value)} 
              value={email}
            />
          </div>
        </div>

        <div>
          <button 
            type='submit'
            className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
            >
              Send request
          </button>
        </div>

      </form>
    </div>
    </>
  )
}
