import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import './output.css';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import { ToastContainer } from 'react-toastify'
import 'react-tooltip/dist/react-tooltip.css'
//theme
                                      
        

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <App />
      <ToastContainer autoClose={1000} />
    </AuthContextProvider>
  </React.StrictMode>
);