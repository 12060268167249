import { Link } from "react-router-dom"
import { useAuthContext } from "../../hooks/useAuthContext"

export default function ZInviteListPong({inviteList}) {

  const { user } = useAuthContext()

  // inviteList = [{ },...]
  // t timestamp
  // jID id of the project
  // a: display name title of the project
  // iUID ping uID the person sending the invite
  // di: display name of the person
  // ei: email
  // oUID pong uID the person being invited
  // do: name
  // eo: email
  // y: yes accepted
  // n: no declined
  // r9-r1: t/f roles he is supposed to do

  return (
    <>
    {inviteList.map(inv => (
      <div key={inv.jID} >
        {inv.oUID == user.uid && 
          <div className=''>
          {!inv.y && 
            <>
              <div className='font-mono text-xs pt-3'>
                By {inv.di} 
              </div>
              <div className='font-body font-bold'>
                &#x2598;
                {inv.a}
              </div>
              <div className='font-mono text-xs text-slate-500'>
                Role:
                {inv.r9 && <>Owner</>}
                {!inv.r9 && inv.r7 && <>Manager</>}
                {!inv.r9 && !inv.r7 && inv.r5 && <>Admin</>}
                {!inv.r9 && !inv.r7 && !inv.r5 && inv.r3 && <>Editor</>}
                {!inv.r9 && !inv.r7 && !inv.r5 && !inv.r3 && inv.r1 && <>Reader</>}
              </div>
              <div className='flex gap-1 font-mono text-xs pt-1'>
                <Link 
                  to={`/wginvitestep4/${inv.jID}`} 
                  className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
                  >
                  accept
                </Link>
                <div className='text-slate-500'>|</div>
                {!inv.n && 
                  <Link 
                    to={`/wginvitestep3/${inv.jID}`} 
                    className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
                    >
                    decline
                  </Link>
                }
                {inv.n && 
                  <div className='font-bold'>
                    declined!
                  </div>
                }
              </div>
            </>
          }
          </div>
        }  
      </div>
    ))
    }
    </>
  )
}