import { Link } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useDocument } from "../../hooks/useDocument";

import ZProjectsList from "../projects/ZProjectsList";
import ZProjectsListParticipation from "../projects/ZProjectsListParticipation";

import ZInviteListPong from "../projects/ZInviteListPong";
import ZPreviewsList from "../knm/ZPreviewsList";

export default function Workspace() {

  const { user } = useAuthContext()
  const { error, document } = useDocument('USERS', user.uid)

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading ...</div>
  }

  return (
    <>
      <div className="px-4 sm:px-8 md:px-12 py-1 ">
        <div className="font-body font-bold italic">
          Workspace
        </div>
        <div className='grid md:grid-cols-3 gap-3 py-3'>

          <div className=''>
            <div className='flex'>
              <div className="font-mono font-bold text-sm" >
                Projects
              </div>
              <Link to="/projectinitiateinfo" className="text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4 pt-1">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
              </Link>
            </div>
            
            <div className='text-xs font-mono text-slate-500 pb-3'>
              initiated by me
            </div>

            <ZProjectsList usersDoc={document} />

          </div>

          <div className=''>

            <div className="font-mono font-bold text-sm" >
              Participation
            </div>
            <div className='text-xs font-mono text-slate-500'>
              in other projects
            </div>

            <ZProjectsListParticipation usersDoc={document} />

            {
            document.I && document.I.length > 0 && 
            document.I.filter(inv => !inv.y).length > 0 &&
            <div className='font-mono'>
              <div className='text-sm font-bold pt-3' >
                Invitation
              </div>
              <div className=''>
                <ZInviteListPong inviteList={document.I} /> 
              </div>
            </div>
            }

          </div>

          <div className=''>

            <div className="font-mono font-bold text-sm" >
              Previews
            </div>
            <div className='text-xs font-mono text-slate-500 pb-3'>
              released by others
            </div>

            {
            document.V && document.V.length > 0 && 
            <div className='font-mono'>
              <div className=''>
                <ZPreviewsList vList={document.V} /> 
              </div>
            </div>
            }

          </div>

        </div>
      </div>
    </>
  )
}
