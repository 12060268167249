import { useState, useEffect } from "react"

import { useDocument } from "../../hooks/useDocument"
import { useFirestore } from "../../hooks/useFirestore"

import { useNavigate, useParams } from "react-router-dom"

// Edit the field eID of the node of block of type Gb Ge
// eID for resources manually given
export default function KNAssignNEID() {
  const { jID, kID, nID } = useParams()
  const { error, document } = useDocument('KNM', kID)
  const { updateDocument, response } = useFirestore('KNM') 
  const navigate = useNavigate()

  const [N, setN] = useState([])
  const [eID, setEID] = useState('')

  useEffect(() => {
    if (document){
      setN(document.N)
      const selectedN = document.N.filter(nde => nde.nID == nID)
      if (selectedN.length > 0) {
        setEID(selectedN[0].eID)
      }
    }
  }, [document])

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading ...</div>
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const updatedN = N.map((nde) => 
    nde.nID == nID ? { ...nde, ...{
        eID,
        //"lastEdited":Date.now().toString()
      }} : nde
    )
    const updates = { 
      N: updatedN
    }
    await updateDocument(kID, updates)
    if (!response.error) {
      navigate(`/knmoverview/${jID}/${kID}`)
    }
  }

  return (
    <>
      <div className="flex justify-center font-mono">
        Assign eID (Einmalig!)
      </div>

    <div className="flex justify-center font-mono">
      <form onSubmit={handleSubmit}>
          <input 
              id="eID"
              type="text" 
              required
              onChange={(e) => setEID(e.target.value)}
              value={eID}
          />
        <div>
          <button 
            type='submit'
            className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
            >
              Assign
          </button>
        </div>
      </form>
    </div>
    </>
  )
}
