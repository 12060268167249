import { useDocument } from "../../hooks/useDocument"
import { useFirestore } from "../../hooks/useFirestore"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useNavigate, useParams } from "react-router-dom"
import { useDocumentA } from "../../hooks/useDocumentA"
import { useDocumentB } from "../../hooks/useDocumentB"
import { useFirestoreA } from "../../hooks/useFirestoreA"
import { useState } from "react"

// within the project jID 
// user with user.uid is releasing
// block with kID for the user with oUID
// the oUID who is not part of WG can only read that block

// When the release button is pressed
// the preview info packet is stored at
// USERS.oUID V:[] and
// KNM.kID V:[]

// in this way, a user knows which blocks are released for him
// the block knows to whom preview is allowed

// The preview packet consists of:
// jID id of the project
// kID of the block
// aJ: display name title of the project
// aK: display title of the block
// iUID ping uID the person sending the invite
// di: display name of the person
// ei: email
// oUID pong uID the person being invited
// do: name
// eo: email
// r9-r1: t/f roles he is supposed to do
// r9-r3: false r1:true
// hi: T/F hide says ping
// ho: T/F hide says pong
// wg: T/F person included in WG of this project 
// t: timestamp

export default function KNMPreviewRelease3() {
  const { user } = useAuthContext()
  // oUID uID of the person invited
  const { jID, kID, oUID } = useParams()

  const { error, document } = useDocument('Projects', jID)
  const { errorA, documentA } = useDocumentA('USERS', oUID)
  const { errorB, documentB } = useDocumentB('KNM', kID)

  const { updateDocument, response } = useFirestore('Projects') 
  const { updateDocumentA, responseA } = useFirestoreA('USERS') 

  const [checkedRoles, setCheckedRoles] = useState([])
  const navigate = useNavigate()

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading ...</div>
  }
  if (errorA) {
    return <div className="error">{errorA}</div>
  }
  if (!documentA) {
    return <div className="loading">Loading ...</div>
  }
  if (errorB) {
    return <div className="error">{errorB}</div>
  }
  if (!documentB) {
    return <div className="loading">Loading ...</div>
  }

  const handleCheck = (e) => {
    if (e.target.checked) {
      setCheckedRoles([...checkedRoles, e.target.value]);
    } else {
      setCheckedRoles(checkedRoles.filter((item) => item !== e.target.value));
    }
  }

  // yet to check that I do not send a request to myself!
  const handleSubmit = async (e) => {
    e.preventDefault()

    let r9 = false
    let r7 = false
    let r5 = false
    let r3 = false
    let r1 = false

    if (checkedRoles.includes("r3")) {
      r3 = true
      r1 = true
    } else if (checkedRoles.includes("r1")) {
      r1 = true
    } 

    // jID id of the project
    // kID of the block
    // aJ: display name title of the project
    // aK: display title of the block
    // iUID ping uID the person sending the invite
    // di: display name of the person
    // ei: email
    // oUID pong uID the person being invited
    // do: name
    // eo: email
    // gJ: project type
    // gK: Block type
    // r9-r1: t/f roles he is supposed to do
    // r9-r3: false r1:true
    // hi: T/F hide says ping
    // ho: T/F hide says pong
    // t: timestamp

    // this is payload for Projects at jID
    // KNM[] the object with kID
    // within that the arr

    let vPayloadPrj = {
      t: Date.now(),
      iUID:user.uid,
      di:user.displayName,
      ei:user.email,
      oUID,
      do:documentA.d,
      eo:documentA.e,
      hi:false, // 
      ho:false, //
      r9,
      r7,
      r5,
      r3,
      r1
    }

    // this payload is for USERS V arr
    let vPayloadUSERS = {
      t: Date.now(),
      jID,
      kID,
      aJ:document.a, // 
      aK:documentB.a,
      iUID:user.uid,
      di:user.displayName,
      ei:user.email,
      hi:false, // 
      ho:false, //
      r9,
      r7,
      r5,
      r3,
      r1
    }

    // get the entry for the block with kID in Project[jID].KNM
    // array

    if (document.KNM.filter(k => k.kID === kID).length > 0) {
      document.KNM.filter(k => k.kID === kID)[0].V.push(
        {...vPayloadPrj}
      )
    }

  
    let arrPongV = documentA.V // 

    // if this packet not already there 
  

    if (arrPongV.filter(e => e.kID === kID).length === 0) {
      arrPongV.push({
        ...vPayloadUSERS
      })
    }

    const updatesPrjKNMV = { 
      KNM: document.KNM // 
    }

    const updatesPongV = { 
      V: arrPongV // 
    }

    // save updatesPrjKNMV to Prj
    await updateDocument(jID, updatesPrjKNMV)
    // save updatesPongV to Pong account
    await updateDocumentA(oUID, updatesPongV)
    if (!response.error && !responseA.error) {
      navigate(`/knmoverview/${jID}/${kID}`)
    }
  }

  return (
    <>
    <div>
      {document.a}
    </div>
    <div>
      {documentA.d}
      &nbsp;
      {documentA.e}
    </div>
    <div>
      Other info about this project. and roles!
    </div>
    <div>
      Roles
    </div>
   
          <div className='col'>
            <input value = "r3" type = "checkbox" onChange = {handleCheck} />
            <span>Editor r3</span>
          </div>
          <div className='col'>
            <input value = "r1" type = "checkbox" onChange = {handleCheck} />
            <span>Reader r1</span>
          </div>

      <form onSubmit={handleSubmit}>

      <div>
          <button 
            type='submit'
            className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
            >
              Release this block
          </button>
        </div>
      </form>


    </>
  )
}