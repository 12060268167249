import { useState, useEffect } from "react"
import { toast } from 'react-toastify'
import { useDocument } from "../../hooks/useDocument"
import { useFirestore } from "../../hooks/useFirestore"
import 'react-toastify/dist/ReactToastify.css'

import xForm from "../../hooks/xForm"

// Edit the field y of the node of block of type Xa
// y upper layer of the text hk
export default function ZEditKNy({kID, nID}) {

  const { error, document } = useDocument('KNM', kID)
  const { updateDocument, response } = useFirestore('KNM') 

  const [N, setN] = useState([])
  const [y, setY] = useState('')
  const { xFormXa } = xForm()
  const notify = () => toast("Updated!")

  useEffect(() => {
    if (document){
      setN(document.N)
      const selectedN = document.N.filter(nde => nde.nID == nID)
      if (selectedN.length > 0) {
        setY(selectedN[0].y)
      }
    }
  }, [document])

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading ...</div>
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const updatedN = N.map((nde) => 
    nde.nID == nID ? { ...nde, ...{
        y,
        //"lastEdited":Date.now().toString()
      }} : nde
    )
    const updates = { 
      N: updatedN
    }
    await updateDocument(kID, updates)
    if (!response.error) {
      notify()
    }
  }

  return (
    <>
      <div className="grid gap-5 sm:grid-cols-2 py-3">
        <div className='px-4 sm:px-12 sm:col-span-1'>
          <div className='font-body font-medium tracking-wide leading-8 text-slate-900'
            dangerouslySetInnerHTML={{ __html: xFormXa(y) }} />
        </div>
      
        <div className="px-4 sm:col-span-1">
          <form onSubmit={handleSubmit}>
          <div className="mt-2">
            <textarea 
              id="editY" 
              name="editY" 
              rows="7" 
              className="block p-3 w-full rounded-md border-0 py-1.5 font-mono font-semibold tracking-wide text-slate-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
              onChange={(e) => setY(e.target.value)}
              value={y}
            >
            </textarea>
          </div>
          <div className='flex justify-between pt-3 px-3'>
          <label htmlFor="editY" className="block text-xs font-mono text-slate-500">Edit this passage (अ-a)</label>
            <button 
              type='submit'
              className='font-mono font-bold text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
            >
              Save
            </button>
          </div>
          </form>
        </div>
    </div>
    </>
  )
}