import { Link } from 'react-router-dom'

export default function ZProjectInfo({project}) {

  return (
    <>
    {/* J: list of my projects q: for sequencing*/}
    {/* project: is one such entry*/}
    {/* jID: unique project ID */}
      <div className='flex gap-1'>
        <div className='font-mono text-sm'>
          <Link to={`/projecteditq/${project.jID}`}
            className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
            >
              {project.q} 
          </Link>
        </div>
        
        <div className='text-slate-700'>
            &#x2598;
        </div>
        <div className='font-body'>
          {
            project.a && 
            <Link 
              to={`/projectoverview/${project.jID}`} 
              className='font-body text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
            > 
              {project.a}
            </Link>            
          }
        </div>
      </div>
    </>
  )
}
