import { useDocument } from "../../hooks/useDocument"
import { useAuthContext } from "../../hooks/useAuthContext"
import { Link, useParams } from "react-router-dom"
import { useDocumentA } from "../../hooks/useDocumentA"

export default function WGInviteStep1() {
  const { user } = useAuthContext()
  const { error, document } = useDocument('USERS', user.uid)
  const { jID } = useParams()
  const { errorA, documentA } = useDocumentA('Projects', jID)

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading ...</div>
  }
  if (errorA) {
    return <div className="error">{errorA}</div>
  }
  if (!documentA) {
    return <div className="loading">Loading ...</div>
  }
  
  return (
    <>
    {/** document.C are my contacts/colleagues */}
    {/** documentA.I are those already invited for this project */}
    {/** they need to be ignored */}
    <div className="px-2 sm:px-16 py-1 ">
      List of my colleagues
    </div>
    {
      document.C && document.C.map(contact => (
        <div key={contact.t}>
          {
            contact.y && documentA.I && 
            (documentA.I.filter(e => e.oUID === contact.o).length === 0) && 
            (documentA.I.filter(e => e.iUID === contact.o).length === 0) && 
              <Link to={`/wginvitestep2/${jID}/${contact.o}`} >
                {contact.d}
              </Link>
          }
        </div>
      ))
    }
    </>
  )
}
