export default function AbhiNameWithLogo() {
  
  return (
    <div className='text-center'>
      <div className='flex justify-center font-body text-2xl'>
        <div className='text-abhi-link'>Abhirāma</div>
        <div className='text-slate-900'>Edition</div>
      </div>
      <div className='font-body font-thin text-slate-800 text-xs italic'>
        striving to present the past for the future
      </div>
    </div>
  )
}