import sortArray from 'sort-array'
import { useAuthContext } from '../../hooks/useAuthContext'
import ZProjectInfo from './ZProjectInfo'
import ZProjectInitiator from './ZProjectInitiator'

export default function ZProjectsListParticipation({usersDoc}) {
  const { user } = useAuthContext()

  return (
    <>
    {/* J: list of my projects q: for sequencing*/}
    {/* jID: unique project ID */}

    {
      usersDoc.J && sortArray(usersDoc.J, {by:"q", order:'asc'}).map(prj => (
        <div key={prj.jID}>
        {prj.iUID != user.uid && 
          <div className='pt-3'>
          <ZProjectInfo project={prj} />
          <ZProjectInitiator project={prj} />
          </div>
        }
        </div>
      ))
    }
    </>
  )
}
