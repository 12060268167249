import { useState, useEffect } from "react"
import { toast } from 'react-toastify'
import { useDocument } from "../../hooks/useDocument"
import { useFirestore } from "../../hooks/useFirestore"
import 'react-toastify/dist/ReactToastify.css'

import xForm from "../../hooks/xForm"
import { useAuthContext } from "../../hooks/useAuthContext"
import useCopyToClipboard from "../../hooks/useCopyToClipboard"

// Edit the field xP of User
// xP palette for  main sanskrit text hk
export default function ZEditxP() {
  const { user } = useAuthContext()
  const { error, document } = useDocument('USERS', user.uid)
  const { updateDocument, response } = useFirestore('USERS') 

  const [xP, setXP] = useState('')
  const { xFormXa } = xForm()
  const notify = () => toast("Updated!")
  const [isCopied, handleCopy] = useCopyToClipboard(3000)

  useEffect(() => {
    if (document){
      setXP(document.xP)
    }
  }, [document])

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading ...</div>
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const updates = { 
      xP
    }
    await updateDocument(user.uid, updates)
    if (!response.error) {
      notify()
    }
  }

  return (
    <>
      <div className="grid gap-5 sm:grid-cols-2 py-3">
        <div className='px-4 sm:px-12 sm:col-span-1'>
          {xP && 
          <div className='font-body tracking-wide text-3xl leading-loose text-slate-900'
            dangerouslySetInnerHTML={{ __html: xFormXa(xP) }} />
          }
        </div>
      
        <div className="px-4 sm:col-span-1">
          <form onSubmit={handleSubmit}>
          <div className="mt-2">
            <textarea 
              id="editXP" 
              name="editXP" 
              rows="5" 
              className="block p-3 w-full rounded-md border-0 py-1.5 font-mono text-xl leading-loose font-semibold tracking-wide text-slate-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-500"
              onChange={(e) => setXP(e.target.value)}
              value={xP}
            >
            </textarea>
          </div>
          <div className='flex justify-between pt-3 px-3'>
          <label htmlFor="editX" className="block text-xs font-mono text-slate-500">Edit this passage (अ-a)</label>
          <div className='flex gap-3 justify-end px-3'>

            <div 
              onClick={() => handleCopy(xP)}>
              { isCopied ? 
                <span className=''>
                  Copied
                </span> : 
                <span 
                className='font-mono font-bold cursor-pointer text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
                >
                  Copy
                </span>
              }
            </div>
            <button 
              type='submit'
              className='font-mono font-bold text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
              >
              Save
            </button>
            </div>
          </div>
          </form>
        </div>
    </div>
    </>
  )
}