export default function ZVList({vList}) {

  // vList = V : list of users to which this block is visible in some form
  // t: Date.now(),
  // iUID: ping
  // di: ping displayName,
  // ei: ping email,
  // oUID,
  // do: pong displayName,
  // eo: pong email ,
  // hi:false, // 
  // ho:false, //
  // r9,
  // r7,
  // r5,
  // r3,
  // r1
  
  // check if oUID is already in the WG of the jID project of this block
  // then ignore them, they are anyway in WG

    return (
      <>
        {
          vList && 
          vList.map(p => (
            <div key={p.oUID} className=' font-mono text-sm pb-1 gap-1'>
            {
              p.do &&
              <div>
                <div className='font-bold'>
                    {p.do}
                </div>
                <div className='text-slate-500'>
                  {p.r9 && <span>Owner</span>}
                  {!p.r9 && p.r7 && <span>Manager</span>}
                  {!p.r9 && !p.r7 && p.r5 && <span>Admin</span>}
                  {!p.r9 && !p.r7 && !p.r5 && p.r3 && <span>Editor</span>}
                  {!p.r9 && !p.r7 && !p.r5 && !p.r3 && p.r1 && <span>Reader</span>}
                </div>
              </div>
            }
            </div>
          ))
        }
      </>
    )
  }
  