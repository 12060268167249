import { useState, useEffect } from "react"
import { toast } from 'react-toastify'
import { useDocument } from "../../hooks/useDocument"
import { useFirestore } from "../../hooks/useFirestore"
import 'react-toastify/dist/ReactToastify.css'
import xForm from "../../hooks/xForm"

// Edit the field z of the node of block of type Xa Gb
export default function ZEditKNz({kID, nID}) {

  const { error, document } = useDocument('KNM', kID)
  const { updateDocument, response } = useFirestore('KNM') 
  const { xFormXaZ } = xForm()

  const [N, setN] = useState([])
  const [z, setZ] = useState('')
  
  const notify = () => toast("Updated!")

  useEffect(() => {
    if (document){
      setN(document.N)
      const selectedN = document.N.filter(nde => nde.nID == nID)
      if (selectedN.length > 0) {
        setZ(selectedN[0].z)
      }
    }
  }, [document])

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading ...</div>
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const updatedN = N.map((nde) => 
    nde.nID == nID ? { ...nde, ...{
        z,
        //"lastEdited":Date.now().toString()
      }} : nde
    )
    const updates = { 
      N: updatedN
    }
    await updateDocument(kID, updates)
    if (!response.error) {
      notify()
    }
  }

  return (
    <>
    <div className="grid gap-5 sm:grid-cols-2 py-3">
        <div className='px-4 sm:px-12 sm:col-span-1'>
          {z && 
          <div className='font-body font-medium tracking-wide leading-8 text-slate-900'
          dangerouslySetInnerHTML={{ __html: xFormXaZ(z) }} />
          }
        </div>
      
        <div className="px-4 sm:col-span-1">
      <form onSubmit={handleSubmit}>
        <div className="mt-2">
          <textarea 
            id="editZ" 
            name="editZ" 
            rows="5" 
            className="block p-3 w-full rounded-md border-0 py-1.5 font-mono font-semibold tracking-wide text-slate-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
            onChange={(e) => setZ(e.target.value)}
            value={z}
          >
          </textarea>
        </div>
        <div className='flex justify-end pt-1 px-3'>
          <button 
            type='submit'
            className='font-mono text-xs text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
          >
            Save
          </button>
        </div>
      </form>
      </div>
      </div>
    </>
  )
}