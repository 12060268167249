import { useAuthContext } from '../../hooks/useAuthContext'
import useCopyToClipboard from '../../hooks/useCopyToClipboard'
import { useDocument } from '../../hooks/useDocument'
import { useParams } from 'react-router-dom'

export default function GetKNMJSON() {
  const { user } = useAuthContext()
  const { kID } = useParams()
  const { error, document } = useDocument("KNM", kID)
  const [isCopied, handleCopy] = useCopyToClipboard(3000)

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading ...</div>
  }

  return (
    <>
     { (
        (user.email === "am@am.de") || (user.email === "anand.mishra@uni-heidelberg.de")
      ) && (
       <div className=''>
       
        
        <div className=''>
          <div className=''>
            {error && <p className=''>{error}</p>}

            <span onClick={() => handleCopy(JSON.stringify(document, null, 2))}>
              { isCopied ? 
                <span className=''>
                  <i className='fas fa-check'></i>&nbsp;
                  <small>copied</small>
                </span> : 
                <span className='animated-link-secondary'>
                  <i className='fas fa-copy'></i>&nbsp;
                  <small>copy</small>
                </span>
              }
            </span>
              <pre>{JSON.stringify(document, null, 2)}</pre>
          </div>
        </div>
      </div> 
      )}
    </>
  )
}