import { Link } from 'react-router-dom'

export default function ZNavbarProject({jID, project}) {

  return (
    <div className="flex font-mono gap-1 text-xs ml-4 py-1">
      <Link to={`/projectoverview/${jID}`} className='font-bold text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'>
        {project.a}
      </Link>
    </div>
  )
}