import { Link } from "react-router-dom"

import { useAuthContext } from "../../hooks/useAuthContext"
import { useDocument } from "../../hooks/useDocument"
import ZMyProfile from "./ZMyProfile"
import ZColleagueProfile from "../colleagues/ZColleagueProfile"

export default function Profile() {
  const { user } = useAuthContext()
  const { error, document } = useDocument('USERS', user.uid)

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading ...</div>
  }

  return (
    <>
      <div className='grid sm:grid-cols-3 py-3'>

        <div className="px-4 sm:px-12 sm:col-span-2 pb-5">
          <div className="flex justify-start">
            <div className="font-mono font-bold text-sm" >
              My Colleagues
            </div>
            <div>
              <Link to="/collaborationreq1" className="text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4 pt-1">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
              </Link>
            </div>
           
          </div>
          <div className="pt-1">
            {
              document.C && document.C.map(doc => (
                <div key={doc.o}>
                  <div>
                    <ZColleagueProfile contact={doc} />
                  </div>
                </div>
              ))
            }
          </div>
        </div>

        <div className="px-4 sm:col-span-1">
          <div className="flex justify-start">
            <div className="font-mono font-bold text-sm" >
              My Profile
            </div>
          </div>
          <div className='pt-3'>
            <ZMyProfile usersDoc={document} />
          </div>
         
        </div>
      </div>
    </>
  )
}