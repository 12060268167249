import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useDocument } from "../../hooks/useDocument";
import { useDocumentA } from "../../hooks/useDocumentA";
import { useFirestore } from "../../hooks/useFirestore";
import { useFirestoreA } from "../../hooks/useFirestoreA";
import { doc } from "firebase/firestore";

// accept
// 
// this means the invPaket for me
// user.uid = oUID in Prj.I should be taken away 
// and put in Prj.WG array

// Moreover, in USERS.V of oUID should remove all entries with project jID
// as the person now has access to the entire project

export default function WGInviteStep4() {
  const { user } = useAuthContext()
  const { jID } = useParams()

  const { error, document } = useDocument('USERS', user.uid)
  const { updateDocument, response } = useFirestore('USERS')
  // documentA is the project
  const { errorA, documentA } = useDocumentA('Projects', jID)
  const { updateDocumentA, responseA } = useFirestoreA('Projects')
  const navigate = useNavigate()

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading ...</div>
  }
  if (errorA) {
    return <div className="error">{errorA}</div>
  }
  if (!documentA) {
    return <div className="loading">Loading ...</div>
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const usersI = document.I
    const usersJ = document.J
    const usersV = document.V
    const projectsI = documentA.I
    const projectsWG = documentA.WG
    const projectsKNM = documentA.KNM

    console.log("usersI")
    console.log(usersI)
    console.log("usersJ")
    console.log(usersJ)
    console.log("usersV")
    console.log(usersV)
    
    // USERS - document
    // take info from usersI and add to usersJ
    // do this if this project is not already there

    const inviteInUsersI = usersI.find(e => e.jID === jID)

    if ((usersJ.filter(e => e.jID === jID).length === 0) && inviteInUsersI) {
      usersJ.push({
        jID, 
        q:'99',
        a:inviteInUsersI.a, // title of the project
        g:inviteInUsersI.g, // signature or type of this project
        t:Date.now(), // time stamp created At
        iUID:inviteInUsersI.iUID, // of the person who pinged
        di:inviteInUsersI.di,
        ei:inviteInUsersI.ei,
        r9:inviteInUsersI.r9, // Owner
        r7:inviteInUsersI.r7, // Manager
        r5:inviteInUsersI.r5, // Admin
        r3:inviteInUsersI.r3, // Editor
        r1:inviteInUsersI.r1  // Reader
      })
    }

    console.log("usersJ Again")
    console.log(usersJ)

    // USERS - document
    // remove the entry from usersI
    const usersIInviteRemoved = usersI.filter(e => e.jID !== jID)

    console.log("usersI with Invite removed")
    console.log(usersIInviteRemoved)
    console.log("usersI Again")
    console.log(usersI)

    // USERS - document
    // remove the entry from usersV
    const usersVPrjBlocksRemoved = usersV.filter(e => e.jID !== jID)

    console.log("usersV with Prj blocks removed")
    console.log(usersVPrjBlocksRemoved)
    console.log("usersV Again")
    console.log(usersV)

    // updates for USERS
    const updatesUSERS = { 
      J: usersJ, // J projects array
      I: usersIInviteRemoved, // I Invite array
      V: usersVPrjBlocksRemoved // V previews array
    }

    // Projects

    console.log("projectsI")
    console.log(projectsI)
    console.log("projectsWG")
    console.log(projectsWG)
    console.log("projectsKNM")
    console.log(projectsKNM)

    // Add in WG

    // take info from projectsI and add to projectsWG
    // do this if this person is not already there
    // projectsWG: di do ei eo iUID oUID r1-r9 t

    const inviteInProjectsI = projectsI.find(e => e.oUID === user.uid)

    if ((projectsWG.filter(e => e.oUID === user.uid).length === 0) && inviteInProjectsI) {
      projectsWG.push({
        t:Date.now(), // time stamp created At
        iUID:inviteInProjectsI.iUID, // of the person who pinged
        oUID:inviteInProjectsI.oUID, // 
        di:inviteInProjectsI.di,
        do:inviteInProjectsI.do,
        ei:inviteInProjectsI.ei,
        eo:inviteInProjectsI.eo,
        r9:inviteInProjectsI.r9, // Owner
        r7:inviteInProjectsI.r7, // Manager
        r5:inviteInProjectsI.r5, // Admin
        r3:inviteInProjectsI.r3, // Editor
        r1:inviteInProjectsI.r1  // Reader
      })
    }

    console.log("projectsWGKopie")
    console.log(documentA.WG)

    // remove the entry from projectsI

    const projectsIInviteRemoved = projectsI.filter(e => e.oUID !== user.uid)

    console.log("projectsI with Invite removed")
    console.log(projectsIInviteRemoved)
    console.log("projectsI Again")
    console.log(projectsI)

    // Projects.KNM.V 
    // here remove the entry with oUID = user.uid
    //var id = prompt("Id of subbrands to remove: ");

    //arr.forEach(function(o) {
    //  o.subBrands = o.subBrands.filter(s => s.id != id);
    //  });

    projectsKNM.forEach(function (block) {
      block.V = block.V.filter(person => person.oUID !== user.uid)
    }
    )

    console.log("projectsKNM Again")
    console.log(projectsKNM)

    const updatesProj = { 
      I: projectsIInviteRemoved, // I Invite array
      WG: projectsWG,
      KNM: projectsKNM
    }

    // save updatesUSERS to my account in USERS collection
    await updateDocument(user.uid, updatesUSERS)

    // save updatesProj to proj
    await updateDocumentA(jID, updatesProj)

    if (!response.error && !responseA.error) {
      navigate('/workspace')
    }
    
  }

  return (
    <>
    <div className="flex justify-center font-mono">
      <div>
        {jID}
      </div>
    </div>

    <div className="flex justify-center font-mono">
      <form onSubmit={handleSubmit}>
        <div>
          <button 
            type='submit'
            className='text-abhi-link hover:text-abhi-link-hover hover:underline hover:underline-offset-4'
            >
              accept
          </button>
        </div>
      </form>
    </div>
    </>
  )
}
